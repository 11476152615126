export function BaiDuMap(ak) {
  return new Promise(function (resolve, reject) {
    window.onload = function () {
      resolve(BMap)
    }
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://api.map.baidu.com/api?v=2.0&ak="+'NDZfAA0GCsEutLYnFzbrIMpxZo34lgN0'+"&callback=init&s=1";
    // script.src = 'http://api.map.baidu.com/api?key=&v=1.3&services=true'
    script.onerror = reject;
    document.head.appendChild(script);
  })
}



// export default function MapLoader () {   // <-- 原作者这里使用的是module.exports
//   return new Promise((resolve, reject) => {
//     if (window.AMap) {
//       resolve(window.AMap)
//     } else {
//       var script = document.createElement('script')
//       script.type = 'text/javascript'
//       script.async = true
//       script.src = 'http://webapi.amap.com/maps?v=1.3&callback=initAMap&key=93a07735c2f19b68bc442f5fb4822f8c'
//       script.onerror = reject
//       document.head.appendChild(script)
//     }
//     window.initAMap = () => {
//       resolve(window.AMap)
//     }
//   })
// }
