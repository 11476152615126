import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import Layout from '../views/Layout.vue'
import { stringifyQuery, parseQuery } from "./utils/query";

Vue.use(VueRouter)

var routes = [
  // {
  //   path: '/home',
  //   name: 'home',
  //   component: Home
  // },
  {
    path: '/',
    name: '',
    component: Layout,
    children: [{
      path: '/main/homePage',
      component: () => import('../views/Main/homePage.vue'),
      name: 'homePage',
      meta: {
        noAuth: true,
        title: '首页'
      }
    },
      {
        path:'/safety',
        component:() => import('../views/Main/Safety.vue'),
        name:'safety',
        meta:{
          noAuth: true,
          title: '安全中心'
        }
      },
      {
        path: '/bigDataVisualization/bigDataScreen',
        component: () => import('../views/bigDataVisualization/bigDataScreen.vue'),
        name: 'bigDataScreen',
        meta: {
          noAuth: true,
          title: '数据大屏'
        }
      },
      {
        path: '/bigDataVisualization/bigDataPhone',
        component: () => import('../views/bigDataVisualization/phone/bigDataPhone.vue'),
        name: 'bigDataPhone',
        meta: {
          noAuth: true,
          title: '村口壹站数字化运营平台'
        }
      },
      {
        path: '/main/login',
        component: () => import('../views/Main/login.vue'),
        name: 'login',
        meta: {
          noAuth: true,
          title: '登录'
        }
      },
      {
        path: '/main/mine',
        component: () => import('../views/Main/mine.vue'),
        name: 'mine',
        meta: {
          noAuth: true,
          title: '我的'
        }
      },
      {
        path: '/main/setting',
        component: () => import('../views/Main/setting.vue'),
        name: 'setting',
        meta: {
          noAuth: true,
          title: '设置'
        }
      },
      {
        path: '/main/relate',
        component: () => import('../views/Main/relate.vue'),
        name: 'relate',
        meta: {
          noAuth: true,
          title: '关于'
        }
      },
      {
        path: '/main/manage',
        component: () => import('../views/Main/manage.vue'),
        name: 'manage',
        meta: {
          noAuth: true,
          title: '账户安全'
        }
      },
      {
        path: '/mine/organization',
        component: () => import('../views/Mine/organization.vue'),
        name: 'organization',
        meta: {
          noAuth: true,
          title: '我的组织'
        }
      },
      {
        path: '/mine/agreement',
        component: () => import('../views/Mine/agreement.vue'),
        name: 'agreement',
        meta: {
          noAuth: true,
          title: '我的协议'
        }
      },
      {
        path: '/mine/card',
        component: () => import('../views/Mine/card.vue'),
        name: 'card',
        meta: {
          noAuth: true,
          title: '我的银行卡'
        }
      },
      {
        path: '/mine/mySafeguard',
        component: () => import('../views/Mine/mySafeguard.vue'),
        name: 'mySafeguard',
        meta: {
          noAuth: true,
          title: '我的保障'
        }
      },
      {
        path: '/sign/waitingSign',
        component: () => import('../views/Sign/waitingSign.vue'),
        name: 'waitingSign',
        meta: {
          noAuth: true,
          title: '我的站点'
        }
      },
      {
        path: '/sign/signed',
        component: () => import('../views/Sign/signed.vue'),
        name: 'signed',
        meta: {
          noAuth: true,
          title: '我的站点'
        }
      },
      {
        path: '/stationagent/cardInfo',
        component: () => import('../views/StationAgent/cardInfo.vue'),
        name: 'cardInfo',
        meta: {
          noAuth: true,
          title: '银行卡信息'
        }
      },
      {
        path: '/stationagent/idcardProving',
        component: () => import('../views/StationAgent/idcardProving.vue'),
        name: 'idcardProving',
        meta: {
          noAuth: true,
          title: '身份信息'
        }
      },
      {
        path: '/stationagent/waitSign',
        component: () => import('../views/StationAgent/waitSign.vue'),
        name: 'waitSign',
        meta: {
          noAuth: true,
          title: '我的站点'
        }
      },
      {
        path: '/stationagent/notSign',
        component: () => import('../views/StationAgent/notSign.vue'),
        name: 'notSign',
        meta: {
          noAuth: true,
          title: '协议签约'
        }
      },
      {
        path: '/stationagent/submitted',
        component: () => import('../views/StationAgent/submitted.vue'),
        name: 'submitted',
        meta: {
          noAuth: true,
          title: '提交成功'
        }
      },
      {
        path: '/stationagent/mysiteZZ',
        component: () => import('../views/StationAgent/mysiteZZ.vue'),
        name: 'mysiteZZ',
        meta: {
          noAuth: true,
          title: '我的站点'
        }
      },
      {
        path: '/stationagent/revenueStrategy',
        component: () => import('../views/StationAgent/revenueStrategy.vue'),
        name: 'revenueStrategy',
        meta: {
          noAuth: true,
          title: '怎么达到这个小目标'
        }
      },
      {
        path: '/stationagent/accountBook',
        component: () => import('../views/StationAgent/accountBook.vue'),
        name: 'accountBook',
        meta: {
          noAuth: true,
          title: '小账本'
        }
      },
      {
        path: '/stationagent/addCustomer',
        component: () => import('../views/StationAgent/addCustomer.vue'),
        name: 'addCustomer',
        meta: {
          noAuth: true,
          title: '新增联系人'
        }
      },
      {
        path: '/stationagent/customerDetail',
        component: () => import('../views/StationAgent/customerDetail.vue'),
        name: 'customerDetail',
        meta: {
          noAuth: true,
          title: '客户详情'
        }
      },
      {
        path: '/stationagent/transactionDetails',
        component: () => import('../views/StationAgent/transactionDetails.vue'),
        name: 'transactionDetails',
        meta: {
          noAuth: true,
          title: '交易详情'
        }
      },
      {
        path: '/stationagent/chooseCustomer',
        component: () => import('../views/StationAgent/chooseCustomer.vue'),
        name: 'chooseCustomer',
        meta: {
          noAuth: true,
          title: '选择客户'
        }
      },
      {
        path: '/stationagent/addTransacrionDetails',
        component: () => import('../views/StationAgent/addTransacrionDetails.vue'),
        name: 'addTransacrionDetails',
        meta: {
          noAuth: true,
          title: '记一笔'
        }
      },
      {
        path: '/stationagent/editTransacrionDetails',
        component: () => import('../views/StationAgent/editTransacrionDetails.vue'),
        name: 'editTransacrionDetails',
        meta: {
          noAuth: true,
          title: '修改交易信息'
        }
      },
      {
        path: '/stationagent/performanceStatistics',
        component: () => import('../views/StationAgent/performanceStatistics.vue'),
        name: 'performanceStatistics',
        meta: {
          noAuth: true,
          title: '业绩统计明细'
        }
      },
      {
        path: '/stationagent/editCustomer',
        component: () => import('../views/StationAgent/editCustomer.vue'),
        name: 'editCustomer',
        meta: {
          noAuth: true,
          title: '修改联系人信息'
        }
      },
      {
        path: '/stationagent/bl',
        component: () => import('../views/StationAgent/businessLicense.vue'),
        name: 'businessLicense',
        meta: {
          noAuth: true,
          title: '营业执照'
        }
      },
      {
        path: '/agreement/registerProtocol',
        component: () => import('../views/Agreement/registerProtocol.vue'),
        name: 'registerProtocol',
        meta: {
          noAuth: true,
          title: '平台注册服务协议'
        }
      },
      {
        path: '/agreement/privacyPolicy',
        component: () => import('../views/Agreement/privacyPolicy.vue'),
        name: 'privacyPolicy',
        meta: {
          noAuth: true,
          title: '隐私权条款'
        }
      },
      {
        path: '/agreement/signBankProtocol',
        component: () => import('../views/Agreement/signBankProtocol.vue'),
        name: 'signBankProtocol',
        meta: {
          noAuth: true,
          title: '农村普惠金融服务合作协议'
        }
      },
      {
        path: '/agreement/supplementProtocol',
        component: () => import('../views/Agreement/supplementProtocol.vue'),
        name: 'supplementProtocol',
        meta: {
          noAuth: true,
          title: '附件四：农村普惠金融业务合规经营承诺书'
        }
      },
      {
        path: '/agreement/tenProhibitionProtocol',
        component: () => import('../views/Agreement/tenProhibitionProtocol.vue'),
        name: 'tenProhibitionProtocol',
        meta: {
          noAuth: true,
          title: '附件三：加盟商十严禁'
        }
      },
      {
        path: '/agreement/cooperationFrameworkProtocol',
        component: () => import('../views/Agreement/cooperationFrameworkProtocol.vue'),
        name: 'cooperationFrameworkProtocol',
        meta: {
          noAuth: true,
          title: '“村口壹站”加盟合作协议'
        }
      },
      {
        path: '/agreement/franchiseApplicationProtocol',
        component: () => import('../views/Agreement/franchiseApplicationProtocol.vue'),
        name: 'franchiseApplicationProtocol',
        meta: {
          noAuth: true,
          title: '"村口壹站"加盟合作协议'
        }
      },
      {
        path: '/agreement/franchiseApplicationProtocolZZ',
        component: () => import('../views/Agreement/franchiseApplicationProtocolZZ.vue'),
        name: 'franchiseApplicationProtocolZZ',
        meta: {
          noAuth: true,
          title: '"村口壹站"加盟合作协议'
        }
      },
      {
        path: '/agreement/signEcommerce',
        component: () => import('../views/Agreement/signEcommerce.vue'),
        name: 'signEcommerce',
        meta: {
          noAuth: true,
          title: '"村口壹站"电商协议'
        }
      },
      {
        path: '/agreement/signInsure',
        component: () => import('../views/Agreement/signInsure.vue'),
        name: 'signInsure',
        meta: {
          noAuth: true,
          title: '"村口壹站"保险推广协议'
        }
      },
      {
        path: '/agreement/managementStandardOfFranchisee',
        component: () => import('../views/Agreement/managementStandardOfFranchisee.vue'),
        name: 'managementStandardOfFranchisee',
        meta: {
          noAuth: true,
          title: "村口壹站加盟商管理规范" // 中国银行
        }
      },
      {

        path: '/agreement/managementStandardOfFranchiseeDY',
        component: () => import('../views/Agreement/managementStandardOfFranchiseeDY.vue'),
        name: 'managementStandardOfFranchisee',
        meta: {
          noAuth: true,
          title: "村口壹站加盟商管理规范" // 东营银行
        }
      },
      {
        path: '/agreement/managementStandardOfFranchiseeZZ',
        component: () => import('../views/Agreement/managementStandardOfFranchiseeZZ.vue'),
        name: 'managementStandardOfFranchiseeZZ',
        meta: {
          noAuth: true,
          title: "村口壹站加盟商管理规范" // 郑州银行
        }
      },
      {
        path: '/agreement/newManagementStandardOfFranchiseeZH',
        component: () => import('../views/Agreement/newManagementStandardOfFranchiseeZH.vue'),
        name: 'newManagementStandardOfFranchiseeZH',
        meta: {
          noAuth: true,
          title: "村口壹站加盟商管理规范" // 新中国银行
        }
      },
      {
        path: '/agreement/newManagementStandardOfFranchiseeHZ',
        component: () => import('../views/Agreement/newManagementStandardOfFranchiseeHZ.vue'),
        name: 'newManagementStandardOfFranchiseeHZ',
        meta: {
          noAuth: true,
          title: "村口壹站加盟商管理规范" // 湖州银行共富驿站
        }
      },
      {
        path: '/agreement/managementStandardOfFranchiseeQS',
        component: () => import('../views/Agreement/managementStandardOfFranchiseeQS.vue'),
        name: 'managementStandardOfFranchiseeQS',
        meta: {
          noAuth: true,
          title: "村口壹站加盟商管理规范"  // 齐商银行
        }
      },
      {
        path: '/agreement/newManagementStandardOfFranchiseeQS',
        component: () => import('../views/Agreement/newManagementStandardOfFranchiseeQS.vue'),
        name: 'newManagementStandardOfFranchiseeQS',
        meta: {
          noAuth: true,
          title: "村口壹站加盟商管理规范"  // 齐商银行
        }
      },
      {
        path: '/search',
        name: 'search',
        component: () => import('../views/searchInfo/search.vue'),
        meta: {
          noAuth: true,
          title: '查询省市信息'
        }
      },
      {
        path: '/ns',
        name:'NewSite',
        component: () => import('../views/site/NewSite.vue'),
        meta: {
          noAuth: true,
          title: '新建站点',
        }
      },
      {
        path: '/nsread',
        name:'NewSiteRead',
        component: () => import('../views/site/NewSiteRead.vue'),
        meta: {
          noAuth: true,
          title: '新建站点'
        }
      },
      {
        path: '/nszz',
        name:'NewSiteZZ',
        component: () => import('../views/site/NewSiteZZ.vue'),
        meta: {
          noAuth: true,
          title: '新建站点'
        }
      },
      {
        path: '/site/mysite',
        name:'mysite',
        component: () => import('../views/site/mysite.vue'),
        meta: {
          noAuth: true,
          title: '站点管理'
        }
      },
      {
        path: '/site/putInto',
        name:'putInto',
        component: () => import('../views/site/putInto.vue'),
        meta: {
          noAuth: true,
          title: '投入管理'
        }
      },
      {
        path: '/facilityRemoval',
        name:'facilityRemoval',
        component: () => import('../views/site/facilityRemoval.vue'),
        meta: {
          noAuth: true,
          title: '出库到站点'
        }
      },
      {
        path: '/otherFacilityRemoval',
        name:'otherFacilityRemoval',
        component: () => import('../views/site/otherFacilityRemoval.vue'),
        meta: {
          noAuth: true,
          title: '其他出库（非站点）'
        }
      },
      {
        path: '/reimburse',
        name:'reimburse',
        component: () => import('../views/site/reimburse.vue'),
        meta: {
          noAuth: true,
          title: '报销'
        }
      },

      {
        path: '/site/monitoringPage',
        name:'monitoringPage',
        component: () => import('../views/site/monitoringPage.vue'),
        meta: {
          noAuth: true,
          title: '站点监控'
        }
      },
      {
        path: '/site/checkpending',
        name:'checkpending',
        component: () => import('../views/site/checkpending.vue'),
        meta: {
          noAuth: true,
          title: '待审核站点'
        }
      },
      {
        path: '/site/sitedetail',
        name:'sitedetail',
        component: () => import('../views/site/sitedetail.vue'),
        meta: {
          noAuth: true,
          title: '站点详情',
        }
      },
      {
        path: '/site/signature',
        name:'signature',
        component: () => import('../views/site/signature.vue'),
        meta: {
          noAuth: true,
          title: '加盟合作申请'
        }
      },
      {
        path: '/site/signatureFour',
        name:'signatureFour',
        component: () => import('../views/site/signatureFour.vue'),
        meta: {
          noAuth: true,
          title: '协议'
        }
      },
      {
        path: '/site/partnerlist',
        name:'partnerlist',
        component: () => import('../views/site/partnerlist.vue'),
        meta: {
          noAuth: true,
          title: '选择参与人员'
        }
      },
      {
        path: '/site/siteInfo',
        name:'siteInfo',
        component: () => import('../views/site/siteInfo.vue'),
        meta: {
          noAuth: true,
          title: '站点信息'
        }
      },
      {
        path: '/site/haowuInfo',
        name:'haowuInfo',
        component: () => import('../views/site/haowuInfo.vue'),
        meta: {
          noAuth: true,
          title: '好物销售及库存'
        }
      },
      {
        path: '/site/siteFileInfo',
        name:'siteFileInfo',
        component: () => import('../views/site/siteFileInfo.vue'),
        meta: {
          noAuth: true,
          title: '站点档案管理'
        }
      },
      {
        path: '/site/labelManagement',
        name:'labelManagement',
        component: () => import('../views/site/labelManagement.vue'),
        meta: {
          noAuth: true,
          title: '站点标签管理'
        }
      },
      {
        path: '/site/villagescore',
        name:'villageScore',
        component: () => import('../views/site/villageScore.vue'),
        meta: {
          noAuth: true,
          title: '站点评分'
        }
      },
      {
        path: '/site/stationagentscore',
        name:'stationAgentScore',
        component: () => import('../views/site/stationAgentScore.vue'),
        meta: {
          noAuth: true,
          title: '站长评分'
        }
      },
      {
        path: '/site/siteBriefInfo',
        name:'siteBriefInfo',
        component: () => import('../views/site/siteBriefInfo.vue'),
        meta: {
          noAuth: true,
          title: '我的站点'
        }
      },
      {
        path: '/audit/audit',
        name:'audit',
        component: () => import('../views/Audit/audit.vue'),
        meta: {
          noAuth: true,
          title: '任务详情'
        }
      },
      {
        path: '/News/newsHome',
        name:'newsHome',
        component: () => import('../views/News/newsHome.vue'),
        meta: {
          noAuth: true,
          title: '村口头条'
        }
      },
      {
        path: '/News/newsDeatail',
        name:'newsDeatail',
        component: () => import('../views/News/newsDeatail.vue'),
        meta: {
          noAuth: true,
          title: '乡村热点资讯'
        }
      },
      {
        path: '/task/myTask',
        name:'myTask',
        component: () => import('../views/task/myTask.vue'),
        meta: {
          noAuth: true,
          title: '我的任务'
        }
      },
      {
        path: '/marketing',
        name:'marketing',
        component: () => import('../views/task/marketing.vue'),
        meta: {
          noAuth: true,
          title: '营销任务'
        }
      },
      {
        path: '/masterCare',
        name:'masterCare',
        component: () => import('../views/task/masterCare.vue'),
        meta: {
          noAuth: true,
          title: '生日关怀'
        }
      },
      {
        path: '/specialTask',
        name:'specialTask',
        component: () => import('../views/task/specialTask.vue'),
        meta: {
          noAuth: true,
          title: '专项检查'
        }
      },
      {
        path: '/task/standardizationTask/standardizationTask',
        name:'standardizationTask',
        component: () => import('../views/task/standardizationTask/standardizationTask.vue'),
        meta: {
          noAuth: true,
          title: '站点标准化'
        }
      },
      {
        path: '/task/standardizationTask/standardTaskList',
        name:'standardTaskList',
        component: () => import('../views/task/standardizationTask/standardTaskList.vue'),
        meta: {
          noAuth: true,
          title: '站点标准化'
        }
      },
      {
        path: '/drving',
        name:'drving',
        component: () => import('../views/tripartite/driving.vue'),
        meta: {
          title: '驾驶证查分'
        }
      },
      {
        path: '/dr',
        name:'drvingResult',
        component: () => import('../views/tripartite/drivingResult'),
        meta: {
          title: '驾驶证查分'
        }
      },
      {
        path: '/express',
        name:'expressInput',
        component: () => import('../views/express/expressInput'),
        meta: {
          title: '快递查询'
        }
      },
      {
        path: '/expressResult',
        name:'expressResult',
        component: () => import('../views/express/expressResult'),
        meta: {
          title: '快递详情'
        }
      },  {
        path: '/feedbackInput',
        name:'feedbackInput',
        component: () => import('../views/feedback/feedbackInput'),
        meta: {
          title: '意见反馈'
        }
      },
      {
        path: '/feedbackResult',
        name:'feedbackResult',
        component: () => import('../views/feedback/feedbackResult'),
        meta: {
          title: '意见反馈'
        }
      },
      {
        path: '/qc/qcsuccess',
        name:'qcsuccess',
        component: () => import('../views/QC/qcsuccess'),
        meta: {
          title: '任务完成'
        }
      },
      {
        path: '/qc/qcInfoList',
        name:'qcInfoList',
        component: () => import('../views/QC/qcInfoList'),
        meta: {
          title: '站点巡检'
        }
      },
      {
        path: '/qc/submitqcInfo',
        name:'submitqcInfo',
        component: () => import('../views/QC/submitqcInfo'),
        meta: {
          title: '站点巡检'
        }
      },
      {
        path: '/ps/psInfo',
        name:'psInfo',
        component: () => import('../views/PS/psInfo'),
        meta: {
          title: '业绩统计'
        }
      },
      {
        path: '/ps/highGoals',
        name:'highGoals',
        component: () => import('../views/PS/highGoals'),
        meta: {
          title: '业绩目标与达成'
        }
      },
      {
        path: '/ps/operationGoals',
        name:'operationGoals',
        component: () => import('../views/PS/operationGoals'),
        meta: {
          title: '业绩目标与达成'
        }
      },
      {
        path: '/ps/cityLevelGoals',
        name:'cityLevelGoals',
        component: () => import('../views/PS/cityLevelGoals'),
        meta: {
          title: '业绩目标与达成'
        }
      },
      {
        path: '/ps/ranking',
        name:'ranking',
        component: () => import('../views/PS/ranking'),
        meta: {
          title: '运营部及成员排名'
        }
      },
      {
        path: '/download',
        name:'download',
        component: () => import('../views/Download/download'),
        meta: {
          title: '下载'
        }
      },
      {
        path: '/qbg',
        name:'qbg',
        component: () => import('../views/QBG/qbg'),
        meta: {
          title: '村口好物'
        }
      },
      {
        path: '/videopage',
        name:'videopage',
        component: () => import('../views/News/videopage'),
        meta: {
          title: '视频'
        }
      },
      {
        path: '/oSSDetail',
        name:'outStandingSiteDetail',
        component: () => import('../views/outstandingSite/outStandSiteDetail'),
        meta: {
          title: '站点详情'
        }
      },
      {
        path: '/eSDetail',
        name:'expressSiteDetail',
        component: () => import('../views/outstandingSite/expressSiteDetail'),
        meta: {
          title: '村口快递'
        }
      },
      {
        path: '/expressJoin',
        name:'expressJoin',
        component: () => import('../views/expressJoin/expressJoin'),
        meta: {
          title: '0元加盟村口快递'
        }
      },
      {
        path: '/eJI',
        name:'expressJoinItem',
        component: () => import('../views/expressJoin/expressJoinItem'),
        meta: {
          title: '0元加盟村口快递'
        }
      },
      {
        path: '/joinSuccess',
        name:'joinSuccess',
        component: () => import('../views/expressJoin/joinSuccess'),
        meta: {
          title: '0元加盟村口快递'
        }
      },
      {
        path: '/performanceDetail',
        name:'performanceDetail',
        component: () => import('../views/StationAgent/stationAgentsubviews/performanceDetail'),
        meta: {
          title: '业绩统计详情'
        }
      },
      {
        path: '/VR',
        name:'VR',
        component: () => import('../views/Main/VRIframe'),
        meta: {
          title: 'VR全景'
        }
      },
      {
        path: '/cash/imcroframe',
        name:'imcroframe',
        component: () => import('../views/StationAgent/cash/imcroframe.vue'),
        meta: {
          title: '灵活用工签约'
        }
      },
      {
        path: '/surveyInput',
        name:'surveyInput',
        component: () => import('../views/visitSurvey/surveyInput'),
        meta: {
          title: '走访调研'
        }
      },
      {
        path: '/surveyDetail',
        name:'surveyDetail',
        component: () => import('../views/visitSurvey/surveyDetail'),
        meta: {
          title: '走访调研'
        }
      },
      {
        path: '/visitSurvey/visitSurveyList',
        name:'visitSurveyList',
        component: () => import('../views/visitSurvey/visitSurveyList'),
        meta: {
          title: '走访调研'
        }
      },
      {
        path: '/visitSurvey/visitsubSurveyListInput',
        name:'visitsubSurveyListInput',
        component: () => import('../views/visitSurvey/visitsubSurveyListInput'),
        meta: {
          title: '走访调研'
        }
      },
      {
        path: '/visitSurvey/visitsubSurveyListDetail',
        name:'visitsubSurveyListDetail',
        component: () => import('../views/visitSurvey/visitsubSurveyListDetail'),
        meta: {
          title: '走访调研'
        }
      },
      {
        path: '/visitSurvey/visitSurveySuccess',
        name:'visitSurveySuccess',
        component: () => import('../views/visitSurvey/visitSurveySuccess'),
        meta: {
          title: '任务完成'
        }
      },
      {
        path: '/deviceCash',
        name:'deviceCash',
        component: () => import('../views/cashPledge/deviceCash'),
        meta: {
          title: '我的设备'
        }
      },
      {
        path: '/cashExplain',
        name:'cashExplain',
        component: () => import('../views/cashPledge/cashExplain'),
        meta: {
          title: '设备押金说明'
        }
      },
      {
        path: '/main/weatherList',
        name:'weatherList',
        component: () => import('../views/Main/weatherList'),
        meta: {
          title: '天气查询'
        }
      },
      {
        path: '/cashPaySuccess',
        name:'cashPaySuccess',
        component: () => import('../views/cashPledge/cachSuccess'),
        meta: {
          title: '设备押金确认成功'
        }
      },
      {
        path: '/ps/siteList',
        name:'siteList',
        component: () => import('../views/PS/siteList'),
        meta: {
          title: '已上线站点'
        }
      },
      {
        path: '/News/newsList',
        name:'newsList',
        component: () => import('../views/News/newsList.vue'),
        meta: {
          noAuth: true,
          title: '特色新闻'
        }
      },
      {
        path: '/News/newsInfo',
        name:'newsInfo',
        component: () => import('../views/News/newsInfo.vue'),
        meta: {
          noAuth: true,
          title: '新闻详情'
        }
      },
      {
        path: '/farmProduct/farmProduct',
        name:'farmProduct',
        component: () => import('../views/farmProduct/farmProduct.vue'),
        meta: {
          noAuth: true,
          title: '主要农产品价格'
        }
      },
      {
        path: '/cash/latestincome',
        name:'latestincome',
        component: () => import('../views/StationAgent/cash/latestincome.vue'),
        meta: {
          noAuth: true,
          title: '最新收入'
        }
      },
      {
        path: '/cash/historyincome',
        name:'historyincome',
        component: () => import('../views/StationAgent/cash/historyincome.vue'),
        meta: {
          noAuth: true,
          title: '累计收入'
        }
      },
      {
        path: '/join/joinck',
        name:'joinck',
        component: () => import('../views/join/joinck'),
        meta: {
          title: '加盟村口'
        }
      },
      {
        path: '/join/joincksuccess',
        name:'joincksuccess',
        component: () => import('../views/join/joincksuccess'),
        meta: {
          title: '加盟村口'
        }
      },
      {
        path: '/more',
        name:'more',
        component: () => import('../views/more/more'),
        meta: {
          title: '全部分类'
        }
      },
      {
        path: '/newsiteName',
        name:'newsiteName',
        component: () => import('../views/site/newsiteName'),
        meta: {
          title: '新建站点'
        }
      },
      {
        path: '/ruleChecking',
        name:'ruleChecking',
        component: () => import('../views/ruleChecking/ruleChecking'),
        meta: {
          title: '规范检查'
        }
      },
      {
        path: '/inspectList',
        name:'inspectList',
        component: () => import('../views/Mine/inspectList'),
        meta: {
          title: '站点规范检查'
        }
      },
      {
        path: '/ruleChecking/checkList',
        name:'checkList',
        component: () => import('../views/ruleChecking/checkList'),
        meta: {
          title: '规范检查'
        }
      },
      {
        path: '/newsiteList',
        name:'newsiteList',
        component: () => import('../views/site/newsiteList'),
        meta: {
          title: '新建站点'
        }
      },
      {
        path: '/newsiteInfo',
        name:'newsiteInfo',
        component: () => import('../views/site/newsiteInfo'),
        meta: {
          title: '新建站点'
        }
      },
      {
        path: '/newmanagerInfo',
        name:'newmanagerInfo',
        component: () => import('../views/site/newmanagerInfo'),
        meta: {
          title: '新建站点'
        }
      },
      {
        path: '/newaddInfo',
        name:'newaddInfo',
        component: () => import('../views/site/newaddInfo'),
        meta: {
          title: '我的站点'
        }
      },
      {
        path: '/searchsite',
        name:'searchsite',
        component: () => import('../views/site/searchsite'),
        meta: {
          title: '我的管理'
        }
      },
      {
        path: '/searchTalent',
        name:'searchTalent',
        component: () => import('../views/talent/searchTalent'),
        meta: {
          title: '达人查询'
        }
      },
      {
        path: '/qa',
        name:'qa',
        component: () => import('../views/Main/qa'),
        meta: {
          title: '常见问题'
        }
      },
      {
        path: '/organizationyy',
        name:'organizationyy',
        component: () => import('../views/Mine/organizationyy'),
        meta: {
          title: '我的组织'
        }
      },
      {
        path: '/performancezz',
        name:'performancezz',
        component: () => import('../views/Main/performancezz'),
        meta: {
          title: '站点业绩统计'
        }
      },
      {
        path: '/performanceAnalysis',
        name:'performanceAnalysis',
        component: () => import('../views/Main/performanceAnalysis'),
        meta: {
          title: '站点业绩分析'
        }
      },
      {
        path: '/task/taskSearch',
        name:'taskSearch',
        component: () => import('../views/task/taskSearch'),
        meta: {
          title: '站点任务搜索'
        }
      },
      {
        path: '/cash/allincome',
        name:'allincome',
        component: () => import('../views/StationAgent/cash/allincome.vue'),
        meta: {
          noAuth: true,
          title: '所有收入'
        }
      },
      {
        path: '/rights/rightsList',
        name:'rightsList',
        component: () => import('../views/rights/rightsList.vue'),
        meta: {
          noAuth: true,
          title: '权益平台'
        }
      },
      {
        path: '/rights/rightsAll',
        name:'rightsAll',
        component: () => import('../views/rights/rightsAll.vue'),
        meta: {
          noAuth: true,
          title: '权益平台'
        }
      },
      {
        path: '/rights/rightsLogin',
        name:'rightsLogin',
        component: () => import('../views/rights/rightsLogin.vue'),
        meta: {
          noAuth: true,
          title: '权益平台'
        }
      },
      {
        path: '/agreement/cooperationFrameworkProtocolNobl',
        component: () => import('../views/Agreement/cooperationFrameworkProtocolNobl.vue'),
        name: 'cooperationFrameworkProtocolNobl',
        meta: {
          noAuth: true,
          title: '村口壹站加盟协议 （非营业执照版）'
        }
      },
      {
        path: '/agreement/signBankProtocolNobl',
        component: () => import('../views/Agreement/signBankProtocolNobl.vue'),
        name: 'signBankProtocolNobl',
        meta: {
          noAuth: true,
          title: '附件一：农村普惠金融服务合作协议 （非营业执照版）'
        }
      },
      {
        path: '/talent/myTalent',
        name:'myTalent',
        component: () => import('../views/talent/myTalent.vue'),
        meta: {
          noAuth: true,
          title: '我的达人'
        }
      },
      {
        path: '/talentInput',
        name:'talentInput',
        component: () => import('../views/talent/talentInput.vue'),
        meta: {
          title: '新建达人'
        }
      },
      {
        path: '/goodsManage',
        name:'goodsManage',
        component: () => import('../views/talent/goodsManage.vue'),
        meta: {
          title: '商品管理'
        }
      },
      {
        path: '/test',
        name:'test',
        component: () => import('../views/test'),
        meta: {
          title: 'test'
        }
      },
      {
        path: '*',
        redirect: '/main/homePage'
      },
    ]
  }
]

var router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  stringifyQuery: stringifyQuery,
  parseQuery: parseQuery,
  routes
})

export default router
