import { MAP_SHOW } from '../mutation-types'
import { MAP_LNG } from '../mutation-types'
import { MAP_LAT } from '../mutation-types'
import { MAP_GPS } from '../mutation-types'

export default {
  namespaced: true,
  state: {
    isShow: false,
    lng: 120.38,
    lat: 30.9,
    gps: ''
  },
  mutations: {
    [MAP_SHOW] (state, mapshow) {
      state.isShow = mapshow
    },
    [MAP_LNG] (state, lng) {
      state.lng = lng
    },
    [MAP_LAT] (state, lat) {
      state.lat = lat
    },
    [MAP_GPS] (state, gps) {
      state.gps = gps
    },
  },
  actions: {
    mapClick({ commit }, mapshow) {
      commit('MAP_SHOW', mapshow)
    },
    changeLng({ commit }, lng) {
      commit('MAP_LNG', lng)
    },
    changeLat({ commit }, lat) {
      commit('MAP_LAT', lat)
    },
    setgps({ commit }, gps) {
      commit('MAP_GPS', gps)
    },
  }
}