const getters = {
  keepAlive:state => state.keepAliveState.keepAlive, //页面缓存
  isShow_Home_SiteManage: state => state.permission.isShow_Home_SiteManage,//首页站点管理
  isShow_Home_MyTask: state => state.permission.isShow_Home_MyTask,//首页我的任务
  isShow_Home_MySite: state => state.permission.isShow_Home_MySite,//首页我的站点
  isShow_Join_Join: state => state.permission.isShow_Join_Join,//提交加盟
  isShow_Checkpending_Audit: state => state.permission.isShow_Checkpending_Audit,//审批
  isShow_SiteList_ToBeAudited: state => state.permission.isShow_SiteList_ToBeAudited,//待审核站点
  isShow_SiteList_PerformanceStatistics:state => state.permission.isShow_SiteList_PerformanceStatistics,//业绩统计
  isShow_SiteList_NewSite:state => state.permission.isShow_SiteList_NewSite,//新建站点
  isShow_Mine_mySite:state => state.permission.isShow_Mine_mySite,//我的页面我的站点
  isShow_Mine_myOrg:state => state.permission.isShow_Mine_myOrg,//我的页面我的组织
  isShow_Mine_myAgreement:state => state.permission.isShow_Mine_myAgreement,//我的页面我的协议
  isShow_Mine_bankCard:state => state.permission.isShow_Mine_bankCard,//我的页面我的银行卡
  isShow_Mine_Safeguard: state => state.permission.isShow_Mine_Safeguard, // 我的页面 我的保障
  isShow_Mine_BuildedSite:state => state.permission.isShow_Mine_BuildedSite,//我的页面已建站点
  isShow_Mine_SignedSite:state => state.permission.isShow_Mine_SignedSite,//我的页面已签约站点
  isShow_Mine_OpenCardNum:state => state.permission.isShow_Mine_OpenCardNum,//我的页面开卡数
  isShow_Mine_PointBalance:state => state.permission.isShow_Mine_PointBalance,//我的页面时点余额
  isShow_Mine_Device:state => state.permission.isShow_Mine_Device,//我的页面我的设备
  isShow_SiteInfo_MasterScore:state => state.permission.isShow_SiteInfo_MasterScore,//站点详情站长评分
  isShow_SiteInfo_SiteScore:state => state.permission.isShow_SiteInfo_SiteScore,//站点详情站点评分
  isShow_Home_Survey:state => state.permission.isShow_Home_Survey,//首页金刚区走访调研
  isShow_Survey_NewSurvey:state => state.permission.isShow_Survey_NewSurvey,//走访调研列表展示新建走访调研按钮
  isShow_Home_ToBeAudited:state => state.permission.isShow_Home_ToBeAudited,//首页显示待审批
  isShow_Security_Center: state => state.permission.isShow_Security_Center,//首页显示安全中心
  isShow_SiteList_InputManagement: state => state.permission.isShow_SiteList_InputManagement

}
export default getters
