<template>
  <div id="app">
    <keep-alive :include="keepAlive" :max="10">
      <router-view ></router-view>
    </keep-alive>
    <loading :show="isLoading" :text="loadingTxt"></loading>
  </div>
</template>

<script>
  import {Toast} from "vant";
  import {getDictByPage} from "./utils/api";
  import common from "./utils/common";
  import {mapGetters} from 'vuex'

  export default {
    data () {
      return {
        loadingTxt: '加载中',
        showMap: true,
      }
    },
    components: {
      Loading: () => import('./components/Loading.vue'),
      mapdiv: () => import('./views/Map/map.vue'),
      [Toast.name]: Toast,

    },
    computed: {
      isLoading () {
        return this.$store.state.loading.isLoading
      },
      mapShow() {
        return this.$store.state.mapshow.isShow
      },
      ...mapGetters([
        'keepAlive'
      ])
    },
    mounted() {
      if (common.getItem('wtToken')){
        this.$store.dispatch('permission/setPermissionAction')
      }
      this.$store.dispatch('keepAliveState/setKeepAliveAction', ['newsHome'])
      Toast.setDefaultOptions({ duration: 3000 });
      this.getAccountPageInfo();
      this.getCustomerPageInfo();
      this.$store.dispatch('mapshow/mapClick', false)
    },
    watch:{
      $route:{ //监听路由变化
        handler:function (to,from) {
          console.log('路由跳转前缓存的页面',this.keepAlive)
        }
      }
    },
    methods:{
      lngchange(lng) {
        this.$store.dispatch('mapshow/changeLng', lng)
      },
      latchange(lat) {
        this.$store.dispatch('mapshow/changeLat', lat)
      },
      gpschange(gps) {
        this.$store.dispatch('mapshow/setgps', gps)
      },
      getAccountPageInfo () {
        if (common.getItem('wtToken') == undefined){
          return
        }
        getDictByPage('accountPage').then(res => {
          if (res.code === 200) {
            let data
            if (res.encryptedStatus === '1') {
              data = JSON.parse(common.decrypt(res.data, res.timestamp));
            } else {
              data = res.data
            }
            if (data.businessCode === 200) {
              common.setItem('tranType',JSON.stringify(JSON.parse(data.data).tranType))
            }
          }
        })
      },
      getCustomerPageInfo () {
        if (common.getItem('wtToken') == undefined){
          return
        }
        getDictByPage('customerPage').then(res => {
          if (res.code === 200) {
            let data
            if (res.encryptedStatus === '1') {
              data = JSON.parse(common.decrypt(res.data, res.timestamp));
            } else {
              data = res.data
            }
            if (data.businessCode === 200) {
              common.setItem('customerGroup',JSON.stringify(JSON.parse(data.data).customerGroup))
            }
          }
        })
      },

    }
  }
</script>


<style lang="less">
  @import "./style/reset.less";
  #app {
    font-family:PingFangSC-Regular,PingFang SC;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    height: 100%;
  }

  /*test*/
</style>
