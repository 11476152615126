

const state = {
  keepAlive:[]


}

const mutations = {
  SET_KEEP_ALIVE: (state, aliveArr) => {
    state.keepAlive = aliveArr
  },
}

const actions = {
  // user login
  setKeepAliveAction ({commit}, stateAlive) {
    commit('SET_KEEP_ALIVE',stateAlive)
  },
  addKeepAliveAction ({commit}, stateAlive) {
    var tmp = [].concat(state.keepAlive);
    if(tmp.indexOf(stateAlive) == -1){
      tmp.push(stateAlive);
      commit('SET_KEEP_ALIVE',tmp)
    } else {
      tmp.pop(stateAlive);
      commit('SET_KEEP_ALIVE',tmp)
      tmp.push(stateAlive)
      commit('SET_KEEP_ALIVE',tmp)
    }

  },
  removeKeepAlive ({commit}, stateAlive) {
    var tmp = [].concat(state.keepAlive);
    tmp.pop(stateAlive);
    commit('SET_KEEP_ALIVE',tmp)
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
