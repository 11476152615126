<template>
  <div id="allmap">
    <div id="l-map"></div>
	<div id="r-result"></div>
  </div>
</template>

<script>
import { BaiDuMap } from '@/utils/map'
export default {
  name: 'home',
  data() {
    return {
      ak: 'RdXGpvs4GG3OeBG1qKExl0YAtsmMbQ56',
    }
  },
  methods: {
    setMap() {
      // 百度地图API功能
        var map = new BMap.Map("l-map");
        map.centerAndZoom(new BMap.Point(116.404, 39.915), 11);

        var options = {
          onSearchComplete: function(results){
            // 判断状态是否正确
            if (local.getStatus() == BMAP_STATUS_SUCCESS){
              var s = [];
              for (var i = 0; i < results.getCurrentNumPois(); i ++){
                s.push(results.getPoi(i).title + ", " + results.getPoi(i).address);

              }
              console.log('results: ', results)
              console.log(s)
              document.getElementById("r-result").innerHTML = s.join("<br/>");
            }
          }
        };
        var local = new BMap.LocalSearch(map, options);
        local.search("银行");
    }
  },
  mounted() {

    this.$nextTick(() => {
        BaiDuMap(this.ak).then(BMap => {
        this.setMap()
      })
    })



  }
}
</script>

<style lang="less" scoped>
#allmap{
  width: 100%;
  height: 80%;
  #l-map{height:300px;width:100%;}
		#r-result{width:100%;}
}
</style>
