import Vue from 'vue'
import Vuex from 'vuex'
import loading from './modules/loading'
import mapshow from './modules/mapshow'
import saveData from 'vue-savedata'
import getters from "./getters";
import permission from "./modules/permission";
import keepAliveState from "./modules/keepAliveState";
Vue.use(Vuex)
var debug = process.env.NODE_ENV !== 'production'
const store = new Vuex.Store({
    modules: {
        mapshow,
        loading,
        permission,
        keepAliveState
    },
    getters,
    plugins: [saveData()]
})
export default  store