export var CHANGE_LOADING = 'CHANGE_LOADING'
export var MAP_SHOW = 'MAP_SHOW'
export var MAP_LNG = 'MAP_LNG'
export var MAP_LAT = 'MAP_LAT'
export var MAP_GPS = 'MAP_GPS'

//keepAliceState
export var SET_KEEP_ALIVE = 'SET_KEEP_ALIVE'
export var ADD_KEEP_ALIVE = 'ADD_KEEP_ALIVE'
export var Remove_KEEP_ALIVE = 'Remove_KEEP_ALIVE'

//permission
export var SET_SiteList_PerformanceStatistics = 'PerformanceStatistics'
export var SET_SiteList_NewSite = 'NewSite'
export var SET_SiteList_ToBeAudited = 'ToBeAudited'
export var SET_Checkpending_Audit = 'Audit'
export var SET_Join_Join = 'Join'
export var SET_Home_MySite = 'MySite_Home'
export var SET_Home_MyTask = 'MyTask'
export var SET_Home_ToBeAudited = 'ToBeAudited_Home'
export var SET_Home_SiteManage = 'SiteManage'
export var SET_Mine_mySite = 'MySite_Mine'
export var SET_Mine_myOrg = 'MyOrganization'
export var SET_Mine_myAgreement = 'MyAgreement'
export var SET_Mine_bankCard = 'MyBankCard'
export var SET_Mine_Safeguard = 'MySafeguard'
export var SET_Mine_BuildedSite = 'BuildedSite'
export var SET_Mine_SignedSite = 'SignedSite'
export var SET_Mine_OpenCardNum = 'OpenCardNum'
export var SET_Mine_PointBalance = 'PointBalance'
export var SET_Mine_Device = 'Page_Mine_Device'
export var SET_SiteInfo_MasterScore = 'MasterScore'
export var SET_SiteInfo_SiteScore = 'SiteScore'
export var SET_Home_Survey = 'Page_Home_Survey'
export var SET_Survey_NewSurvey = 'Page_Survey_NewSurvey'
export var Page_Home = 'Page_Home'
export var Page_Join = 'Page_Join'
export var Page_Audit = 'Page_Audit'
export var Page_SiteList_Home = 'Page_SiteList_Home'
export var Page_SiteList_Mine = 'Page_SiteList_Mine'
export var Page_Audit_Mysite = 'Page_Audit_Mysite'
export var Page_Audit_checkpending = 'Page_Audit_checkpending'
export var Page_Mine = 'Page_Mine'
export var SET_Security_Center  = 'Security_Center'
export var SET_SiteList_InputManagement = 'InputManagement'

export var itemsArr = [SET_SiteInfo_SiteScore, SET_SiteInfo_MasterScore, SET_Join_Join, SET_Home_MyTask, SET_Home_SiteManage, SET_Mine_myOrg, SET_Mine_mySite, SET_Home_MySite, SET_Home_ToBeAudited,
    SET_Mine_myAgreement, SET_Mine_bankCard, SET_Mine_Safeguard, SET_Mine_BuildedSite, SET_Mine_SignedSite, SET_Mine_OpenCardNum, SET_Mine_PointBalance,
    SET_Home_Survey, SET_Survey_NewSurvey, SET_Mine_Device, SET_Security_Center,SET_SiteList_InputManagement
]
export var Page_FromArr = [SET_SiteList_PerformanceStatistics, SET_SiteList_NewSite, SET_SiteList_ToBeAudited, SET_Checkpending_Audit]

