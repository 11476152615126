import axios from 'axios'
import store from '../store'
import {Toast} from "vant";

/**
 * 请求拦截器
 */
axios.interceptors.request.use(
  config => {
    store.commit('loading/CHANGE_LOADING', true)
    config.withCredentials = true
    return config
  },
  err => Promise.error(err)
)
/**
 * 响应拦截器
 */
axios.interceptors.response.use(
  res => {
    store.commit('loading/CHANGE_LOADING', false)
    if(res.status === 200){
      if(res.data.data && res.data.data.businessCode == 10008){
        window.location.href = window.location.origin + '/main/login'
        return
      } else if(res.data.code === 200 && res.data.data.businessCode == 30061){
        // 超过30天未更新的失效站点，弹框，不toast
      } else if (res.data.code === 200 && res.data.data.businessCode !== 200 && res.data.data.businessMessage !=undefined &&  res.data.showMsg){
          Toast(res.data.data.businessMessage)
      }
      return Promise.resolve(res.data)
    }else{
        Toast(res.data.message)
      return Promise.reject(res)
    }

  },
  (err) => {
      // Toast(err.toString())
      Toast('服务器开小差了,请稍后再试')
    store.commit('loading/CHANGE_LOADING', false)
    return err
  }
)

export default axios
