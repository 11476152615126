
import common from './common'

export default {
  // 存储当前页面
  pageArr: [],
  // 验证手机号码
  phoneVerify (phone) {
   // let reg = /^1(3[0-9]|4[^01234]|5[^4]|6[6]|7[^0129]|8[0-9]|9[189])\d{8}$/;
    let reg =/^1[0-9]{10}$/;
    return reg.test(phone);
  },
  // 170、171号段
  phoneVerify1 (phone) {
    let reg = /^1(3[0-9]|4[^01234]|5[^4]|6[6]|7[^29]|8[0-9]|9[189])\d{8}$/;
    return reg.test(phone);
  },
  // 验证6位验证码
  numberVerify (num) {
    let reg = /^\d{6}$/gi;
    return reg.test(num);
  },
  // 验证中文姓名
  nameVerify (name) {
    // let reg = /^[\u4e00-\u9fa5]+([\u4e00-\u9fa5]|·)*[\u4e00-\u9fa5]+$/;
    let reg = /^[\u4e00-\u9fa5]{1,}((·[\u4e00-\u9fa5]{1,}){0,3})$/;
    return reg.test(name);
  },
  // 验证身份证号
  IdCardVerify (code) {
    // let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|[a-zA-Z])$)/;
    // let reg = /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
    // return reg.test(code);
    code = code.toLowerCase()
    var arr2 = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
    var arr3 = [1, 0, 'x', 9, 8, 7, 6, 5, 4, 3, 2];
    if (code.length == 18) {
      var arr = code.split('');
      var s;
      var reg = /^\d+$/;
      var pd = 0;
      for (var i = 0; i < 17; i++) {
        if (reg.test(arr[i])) {
          s = true;
          pd = arr[i] * arr2[i] + pd;
        } else {
          s = false;
          break;
        }
      }
      if (s = true) {
        var r = pd % 11;
        if (arr[17] == arr3[r]) {
          var year18 = code.substring(6,10);
          var month18 = code.substring(10,12);
          var day18 = code.substring(12,14);
          console.log(year18,month18,day18);
          var tmp_date18 = new Date(year18,parseFloat(month18) - 1,parseFloat(day18));
          console.log(tmp_year18);
          var tmp_year18 = tmp_date18.getFullYear().toString();
          console.log(tmp_year18);
          if ((tmp_year18 != parseFloat(year18)) || (tmp_date18.getMonth() != parseFloat(month18) - 1) ||  (tmp_date18.getDate() != parseFloat(day18))){
            return false;
          } else {
            return true;
          }
        } else {
          return false
        }
      } else {
        return false
      }
    } else if(code.length == 15){
      var year = code.substring(6,8);
      var month = code.substring(8,10);
      var day = code.substring(10,12);
      var tmp_date = new Date(year,parseFloat(month) - 1,parseFloat(day));
      var tmp_year = tmp_date.getFullYear().toString().substring(2,4);
      if ((tmp_year != parseFloat(year)) || (tmp_date.getMonth() != parseFloat(month) - 1) ||  (tmp_date.getDate() != parseFloat(day))){
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  },
  // 验证图片格式
  imageVerify (type) {
    let reg = /(jpeg|png|jpg)/i;
    return reg.test(type);
  },
  // 验证单位电话
  telVerify (num) {
    let reg = /^\d*$/gi;
    return reg.test(num);
  },
  // 验证银行卡号
  bankCardVerify (code) {
    let reg = /^\d{15,19}$/gi;
    return reg.test(code);
  },
  // 验证地址(中文、字母和数字-)
  addressVerify (add) {
    let pattern = /^[\u4e00-\u9fa5a-zA-Z0-9-]+$/;
    return pattern.test(add);
  },
  // 至少一个中文
  isAddress (add) {
    let pattern = /[\u4E00-\u9FA5]+/;
    return pattern.test(add);
  },
  // 英文名字 拼音
  isEnName (add) {
    let pattern = /^[a-zA-Z]+$/g;
    return pattern.test(add);
  },
  // 邮箱地址
  isEmailAddress (add) {
    let pattern = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
    return pattern.test(add);
  },
  // 金额
  isAmountOfMoney (add) {
    let pattern = /^\d+(?:\.\d{1,2})?$/;
    return pattern.test(add);
  },
  //获取byte字节数
  getBLen (str) {
    if (str == null) return 0;
    if (typeof str != "string") {
      str += "";
    }
    return str.replace(/[^\x00-\xff]/g, "01").length;
  },
  // 判断中文个数
  checksum (chars) {
    var reg = /[\u4E00-\u9FA5]/g;
    if (reg.test(chars)) {
      return chars.match(reg).length;
    } else {
      return 0;
    }
  },
  getAge(identityCard) {
    var len = (identityCard + "").length;
    if (len == 0) {
        return 0;
    } else {
        if ((len != 15) && (len != 18))//身份证号码只能为15位或18位其它不合法
        {
            return 0;
        }
    }
    var strBirthday = "";
    if (len == 18)//处理18位的身份证号码从号码中得到生日和性别代码
    {
        strBirthday = identityCard.substr(6, 4) + "/" + identityCard.substr(10, 2) + "/" + identityCard.substr(12, 2);
    }
    if (len == 15) {
        strBirthday = "19" + identityCard.substr(6, 2) + "/" + identityCard.substr(8, 2) + "/" + identityCard.substr(10, 2);
    }
    //时间字符串里，必须是“/”
    var birthDate = new Date(strBirthday);
    var nowDateTime = new Date();
    var age = nowDateTime.getFullYear() - birthDate.getFullYear();
    //再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
    if (nowDateTime.getMonth() < birthDate.getMonth() || (nowDateTime.getMonth() == birthDate.getMonth() && nowDateTime.getDate() < birthDate.getDate())) {
        age--;
    }
    return age
  },
  subString1 (str, len) {
    var regexp = /[^\x00-\xff]/g; // 正在表达式匹配中文
    // 当字符串字节长度小于指定的字节长度时
    if (str.replace(regexp, "aa").length <= len) {
      return str;
    }
    // 假设指定长度内都是中文
    var m = Math.floor(len / 2);
    for (var i = m, j = str.length; i < j; i++) {
      // 当截取字符串字节长度满足指定的字节长度
      if (str.substring(0, i).replace(regexp, "aa").length >= 0) {
        return str.substring(0, i);
      }
    }
    return str;
  },
  setItem (key, value) {
    let curTime = new Date().getTime();
    window.localStorage.setItem(
      key,
      JSON.stringify({ data: value, time: curTime })
    );
  },
  getItem (key) {
    let data = window.localStorage.getItem(key);
    if (data) {
      let dataObj = JSON.parse(data);
      let dataObjDatatoJson = dataObj.data;
      return dataObjDatatoJson;
      // if(new Date().getTime() - dataObj.time > 60*60*1000){
      //   // let token = new GUID();
      //   // common.setItem('tokenjx',token.newGUID());
      //   common.setItem('tokenjx', '');
      // }else{
      //   let dataObjDatatoJson = dataObj.data
      //   return dataObjDatatoJson;
      // }
    }
  },
  removeItem(key){
    window.localStorage.removeItem(key);
  },
  // 图片压缩
  compress (img, callback) {
    let canvas = document.createElement("canvas")
    let context = canvas.getContext("2d")
    let width = img.width;
    let height = img.height;
    // 如果图片大于四百万像素，计算压缩比并将大小压至400万以下
    let ratio;
    if ((ratio = (width * height) / 4000000) > 1) {
      ratio = Math.sqrt(ratio)
      width /= ratio
      height /= ratio
    } else {
      ratio = 1
    }
    canvas.width = width
    canvas.height = height
    context.fillStyle = "#fff"
    context.fillRect(0, 0, canvas.width, canvas.height)
    context.drawImage(img, 0, 0, width, height)
    let base64 = canvas.toDataURL("image/jpeg", 0.5)
    canvas = null
    callback ? callback(base64) : null; //调用回调函数
  },
  // 图片压缩
  compressSize (img, callback) {
    let canvas = document.createElement("canvas")
    let context = canvas.getContext("2d")
    let width = img.width;
    let height = img.height;
    // 如果图片大于四百万像素，计算压缩比并将大小压至400万以下
    let ratio;
    if ((ratio = (width * height) / 30000) > 1) {
      ratio = Math.sqrt(ratio)
      width /= ratio
      height /= ratio
    } else {
      ratio = 1
    }
    canvas.width = width
    canvas.height = height
    context.fillStyle = 'rgba(255,255,255,0)'
    context.fillRect(0, 0, canvas.width, canvas.height)
    context.drawImage(img, 0, 0, width, height)
    let base64 = canvas.toDataURL("image/png", 1)
    canvas = null
    callback ? callback(base64) : null; //调用回调函数
  },
  rotateBase64Img (src, edg, callback) {
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    var imgW;//图片宽度
    var imgH;//图片高度
    var size;//canvas初始大小
    if (edg % 90 != 0) {
      console.error("旋转角度必须是90的倍数!");
      throw '旋转角度必须是90的倍数!';
    }
    (edg < 0) && (edg = (edg % 360) + 360)
    var quadrant = (edg / 90) % 4; //旋转象限
    var cutCoor = { sx: 0, sy: 0, ex: 0, ey: 0 }; //裁剪坐标
    var image = new Image();
    image.crossOrigin = "anonymous"
    image.src = src;
    image.onload = function () {
      imgW = image.width;
      imgH = image.height;
      size = imgW > imgH ? imgW : imgH;
      canvas.width = size * 2;
      canvas.height = size * 2;
      switch (quadrant) {
        case 0:
          cutCoor.sx = size;
          cutCoor.sy = size;
          cutCoor.ex = size + imgW;
          cutCoor.ey = size + imgH;
          break;
        case 1:
          cutCoor.sx = size - imgH;
          cutCoor.sy = size;
          cutCoor.ex = size;
          cutCoor.ey = size + imgW;
          break;
        case 2:
          cutCoor.sx = size - imgW;
          cutCoor.sy = size - imgH;
          cutCoor.ex = size;
          cutCoor.ey = size;
          break;
        case 3:
          cutCoor.sx = size;
          cutCoor.sy = size - imgW;
          cutCoor.ex = size + imgH;
          cutCoor.ey = size + imgW;
          break;
      }
      ctx.translate(size, size);
      ctx.rotate(edg * Math.PI / 180);
      ctx.drawImage(image, 0, 0);
      var imgData = ctx.getImageData(cutCoor.sx, cutCoor.sy, cutCoor.ex, cutCoor.ey);
      if (quadrant % 2 == 0) {
        canvas.width = imgW;
        canvas.height = imgH;
      } else {
        canvas.width = imgH;
        canvas.height = imgW;
      }
      ctx.putImageData(imgData, 0, 0)

      let img = new Image()
      img.src = canvas.toDataURL()
      canvas = null
      img.onload = function() {
        common.compressSize(img, (dataURL)=>{
          callback ? callback(dataURL) : null; //调用回调函数
        })
      }

    };

  },
  checkStep (step) {
    let stepArr = [
      { name: "register", value: "1" },
      { name: "nameProving", value: "2" },
      { name: "videoProving", value: "3" },
      { name: "addressInfo", value: "4" },
      { name: "fenqi", value: "5" },
      { name: "phoneProving", value: "6" }
    ];
    for (let obj of stepArr) {
      if (obj.value == step) {
        return obj.name;
      }
    }
  },
  // 重复出现6次以上
  isRepeat (v) {
    var count = 1;
    for (var i = 0; i < v.length; i++) {
      if (v[i] == v[i + 1]) {
        count++;
        if (count >= 6) {
          console.log(count);
          return false;
        }
      } else {
        count = 1;
      }
    }
    return true;
  },
  // 连续号码6位以上顺增
  isPlus (v) {
    var count = 1;
    for (var i = 0; i < v.length; i++) {
      if (v[i] - -1 == v[i + 1]) {
        count++;
        if (count >= 6) {
          return false;
        }
      } else {
        count = 1;
      }
    }
    return true;
  },
  // 连续号码6位以上顺减
  isMinus (v) {
    var count = 1;
    for (var i = 0; i < v.length; i++) {
      if (v[i] > v[i + 1]) {
        if (v[i] - 1 == v[i + 1]) {
          count++;
          if (count >= 6) {
            return false;
          }
        } else {
          count = 1;
        }
      }
    }
    return true;
  },
  // 获取ip、ip地区码
  getIP () {
    let IP = returnCitySN["cip"];
    let IP_AREA_CODE = returnCitySN["cid"];
    let obj = {
      IP: IP,
      IP_AREA_CODE: IP_AREA_CODE
    };
    return obj;
  },
  // 获取经度、纬度、lbs地区码
  getLBS () {
    let LBS = {
      lat: "",
      lng: "",
      adcode: ""
    };
    return new Promise((resolve, reject) => {
      let geolocation = new qq.maps.Geolocation(
        "HTIBZ-JNGW5-TXFIQ-Q45OI-2DN6T-Q7BSR",
        "ifsoft"
      );
      geolocation.getLocation(
        function (position) {
          LBS.lat = position.lat;
          LBS.lng = position.lng;
          LBS.adcode = position.adcode;
          resolve(position);
        },
        function () {
          console.log("定位失败");
        },
        { timeout: 8000 }
      );
    });
  },
  // 获取网络类型
  getNETTYPE () {
    let str = navigator.appVersion;
    let dee;
    let str2 = null;
    if (str.indexOf("NetType/") != -1) {
      dee = str.split(" ");
      for (let i = 0; i < dee.length; i++) {
        if (dee[i].indexOf("NetType/") != -1) {
          str2 = dee[i].substring(dee[i].indexOf("/") + 1, dee[i].length);
          console.log("网络类型:" + str2);
        }
      }
    } else {
      console.log("无法获取网络类型");
    }
    return str2;
  },
  // input ios 12以上失焦空白
  kickBack () {
    let ver = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
    if (!ver) {
      return;
    }
    ver = parseInt(ver[1], 10);
    if (ver < 12) {
      return;
    }
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollTop + 1);
      document.body.scrollTop >= 1 &&
        window.scrollTo(0, document.body.scrollTop - 1);
    }, 10);
  },
  // 年-月-日
  time (time) {
    var d = new Date(time)
    var year = d.getFullYear()
    var month = (d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : '' + (d.getMonth() + 1)
    var day = d.getDate() < 10 ? '0' + d.getDate() : '' + d.getDate()
    return year + '-' + month + '-' + day
  },
  // 年-月-日 时:分:秒
  timeHMS (time) {
    var d = new Date(time)
    var year = d.getFullYear()
    var month = (d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : '' + (d.getMonth() + 1)
    var day = d.getDate() < 10 ? '0' + d.getDate() : '' + d.getDate()
    var hour = d.getHours() < 10 ? '0' + d.getHours() : '' + d.getHours()
    var minutes = d.getMinutes() < 10 ? '0' + d.getMinutes() : '' + d.getMinutes()
    var seconds = d.getSeconds() < 10 ? '0' + d.getSeconds() : '' + d.getSeconds()
    return year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':' + seconds
  },
  // 本月第一天
  timeMonth(time) {
    var d = new Date(time)
    var year = d.getFullYear()
    var month = (d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : '' + (d.getMonth() + 1)
    return year + '-' + month + '-' + '1'
  },
  getOsVersion () {
    // 获取系统及版本号
    var u = navigator.userAgent,
      version = "";
    if (u.indexOf("Mac OS X") > -1) {
      // ios
      var regStr_saf = /OS [\d._]*/gi;
      var verinfo = u.match(regStr_saf);
      version =
        "IOS" + (verinfo + "").replace(/[^0-9|_.]/gi, "").replace(/_/gi, ".");
    } else if (u.indexOf("Android") > -1 || u.indexOf("Linux") > -1) {
      // android
      version =
        "Android" +
        u.substr(
          u.indexOf("Android") + 8,
          u.indexOf(";", u.indexOf("Android")) - u.indexOf("Android") - 8
        );
    } else if (u.indexOf("BB10") > -1) {
      // 黑莓bb10系统
      version =
        "blackberry" +
        u.substr(
          u.indexOf("BB10") + 5,
          u.indexOf(";", u.indexOf("BB10")) - u.indexOf("BB10") - 5
        );
    } else if (u.indexOf("IEMobile") > -1) {
      // windows phone
      version =
        "winphone" +
        u.substr(
          u.indexOf("IEMobile") + 9,
          u.indexOf(";", u.indexOf("IEMobile")) - u.indexOf("IEMobile") - 9
        );
    } else {
      var userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.indexOf("windows nt 5.0") > -1) {
        version = "Windows 2000";
      } else if (
        userAgent.indexOf("windows nt 5.1") > -1 ||
        userAgent.indexOf("windows nt 5.2") > -1
      ) {
        version = "Windows XP";
      } else if (userAgent.indexOf("windows nt 6.0") > -1) {
        version = "Windows Vista";
      } else if (
        userAgent.indexOf("windows nt 6.1") > -1 ||
        userAgent.indexOf("windows 7") > -1
      ) {
        version = "Windows 7";
      } else if (
        userAgent.indexOf("windows nt 6.2") > -1 ||
        userAgent.indexOf("windows 8") > -1
      ) {
        version = "Windows 8";
      } else if (userAgent.indexOf("windows nt 6.3") > -1) {
        version = "Windows 8.1";
      } else if (
        userAgent.indexOf("windows nt 6.2") > -1 ||
        userAgent.indexOf("windows nt 10.0") > -1
      ) {
        version = "Windows 10";
      } else {
        version = "Unknown";
      }
    }
    return version;
  },
  getWechatVersion () {
    // 获取微信版本号
    var wechatInfo = navigator.userAgent.match(/MicroMessenger\/([\d\\.]+)/i);
    if (wechatInfo) {
      return wechatInfo[1];
    } else {
      return "Unknown";
    }
  },
  aseKey: "N==KNT|QJZN2019", //秘钥必须为：8/16/32位
  iv: "0000000000000000",
  // iv: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
  encrypt (message, timestamp) {
    // 传入加密内容和时间戳
    //加密
    var encrypt = CryptoJS.AES.encrypt(
      message,
      CryptoJS.enc.Utf8.parse(md5(timestamp + this.aseKey).substr(8, 16)),
      {
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        iv: CryptoJS.enc.Utf8.parse(this.iv)
      }
    ).toString();
    // console.log('encrypt', encrypt);
    return encrypt;
  },
  decrypt (encrypt, timestamp) {
    // 传入加密内容和时间戳
    //解密
    var decrypt = CryptoJS.AES.decrypt(
      encrypt,
      CryptoJS.enc.Utf8.parse(md5(timestamp + this.aseKey).substr(8, 16)),
      {
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        iv: CryptoJS.enc.Utf8.parse(this.iv)
      }
    ).toString(CryptoJS.enc.Utf8);
    // console.log('decrypt:', decrypt);
    return decrypt;
  },
  // 双角度人脸识别错误码
  faceErrorMessage (data, dom) {
    switch (data) {
      case "MASK":
        dom.$toast("系统认为是假脸");
        break;
      case "SCREEN_REPLAY":
        dom.$toast("屏幕翻拍");
        break;
      case "FACEGEN":
        dom.$toast("软件合成脸");
        break;
      // Raw-ValidateFrontFace
      case "IMAGE_ERROR_UNSUPPORTED_FORMAT":
        dom.$toast("图像无法正确解析，请重新拍摄");
        break;
      case "NO_FACE_FOUND":
        dom.$toast("图像没有检测到人脸，请重新拍摄");
        break;
      case "MULTIPLE_FACES":
        dom.$toast("图像检测到多张人脸，请重新拍摄");
        break;
      case "FACE_BLURRED":
        dom.$toast("人脸太模糊，请重新拍摄");
        break;
      case "FACE_POOR_LIGHT":
        dom.$toast("人脸区过亮或过暗，请重新拍摄");
        break;
      case "FACE_NOT_FRONT":
        dom.$toast("图像中的人脸非正面朝向，请重新拍摄");
        break;
      case "FACE_NOT_PROPER_POSITION":
        dom.$toast("人脸位置不合适，请重新拍摄");
        break;
      // Raw-ValidateSideFace
      case "INVALID_TOKEN":
        dom.$toast("系统异常，请稍后重试");
        break;
      case "FACE_NOT_SIDE":
        dom.$toast("图像中的人脸非合适的侧脸照，请重新拍摄");
        break;
      // 通用
      case "AUTHENTICATION_ERROR":
        dom.$toast("系统异常，请稍后重试");
        break;
      case "AUTHORIZATION_ERROR":
        dom.$toast("系统异常，请稍后重试");
        break;
      case "CONCURRENCY_LIMIT_EXCEEDED":
        dom.$toast("系统异常，请稍后重试");
        break;
      case "MISSING_ARGUMENTS":
        dom.$toast("系统异常，请稍后重试");
        break;
      case "BAD_ARGUMENTS":
        dom.$toast("系统异常，请稍后重试");
        break;
      case "API_NOT_FOUND":
        dom.$toast("系统异常，请稍后重试");
        break;
      case "INTERNAL_ERROR":
        dom.$toast("系统异常，请稍后重试");
        break;
      default:
        dom.$toast("系统异常，请稍后重试");
        break;
    }
  },
  // 视频活体错误码
  videoErrorMessage (data, dom) {
    switch (data) {
      case "MASK":
        dom.$toast("系统认为是假脸");
        break;
      case "SCREEN_REPLAY":
        dom.$toast("屏幕翻拍");
        break;
      case "FACEGEN":
        dom.$toast("软件合成脸");
        break;
      case "INVALID_TOKEN":
        dom.$toast("系统异常，请稍后重试");
        break;
      case "VIDEO_FACE_NOT_FOUND":
        dom.$toast("上传的视频中没有检测到人脸");
        break;
      case "VIDEO_LOW_FACE_QUALITY":
        dom.$toast("上传的视频中人脸质量太差");
        break;
      case "VIDEO_INVALID_DURATION":
        dom.$toast("上传的视频时长不对(3~6s之间)");
        break;
      case "VIDEO_MULTIPLE_FACES":
        dom.$toast("上传的视频中有多张人脸");
        break;
      case "VIDEO_NO_AUDIO":
        dom.$toast(
          "上传视频中没有音轨，通常是由于手机的录音权限没有打开或者受到阻止"
        );
        break;
      case "VIDEO_UNSUPPORTED_FORMAT":
        dom.$toast("视频无法解析，有可能是ffmpeg不支持的格式或视频有破损");
        break;
      // 通用
      case "AUTHENTICATION_ERROR":
        dom.$toast("系统异常，请稍后重试");
        break;
      case "AUTHORIZATION_ERROR":
        dom.$toast("系统异常，请稍后重试");
        break;
      case "CONCURRENCY_LIMIT_EXCEEDED":
        dom.$toast("系统异常，请稍后重试");
        break;
      case "MISSING_ARGUMENTS":
        dom.$toast("系统异常，请稍后重试");
        break;
      case "BAD_ARGUMENTS":
        dom.$toast("系统异常，请稍后重试");
        break;
      case "API_NOT_FOUND":
        dom.$toast("系统异常，请稍后重试");
        break;
      case "INTERNAL_ERROR":
        dom.$toast("系统异常，请稍后重试");
        break;
      default:
        dom.$toast("系统异常，请稍后重试");
        break;
    }
  },
  // 身份证错误码 视频活体错误码
  IDErrorMessage (data, dom) {
    switch (data) {
      case "MASK":
        dom.$toast("系统认为是假脸");
        break;
      case "SCREEN_REPLAY":
        dom.$toast("屏幕翻拍");
        break;
      case "FACEGEN":
        dom.$toast("软件合成脸");
        break;
      case "SELFIE_INCONSISTENT":
        dom.$toast("正脸侧脸照不一致");
        break;
      case "SELFIE_METADATA_INCONSISTENT":
        dom.$toast("正侧脸照的照片不都为JPG编码、或者元信息不一致");
        break;
      case "VIDEO_SR_ERROR":
        dom.$toast("语音识别结果与要求不符");
        break;
      case "VIDEO_NOT_SYNCHRONIZED":
        dom.$toast("视频中唇语识别错误");
        break;
      case "VIDEO_FACE_INCONSISTENT":
        dom.$toast("视频过程中的人脸不一致");
        break;
      case "LOW_QUALITY":
        dom.$toast("人脸图像质量太低，请重新拍摄");
        break;
      // Raw-Verify
      case "INVALID_TOKEN":
        dom.$toast("系统异常，请稍后重试");
        break;
      case "IMAGE_ERROR_UNSUPPORTED_FORMAT: image_ref1":
        dom.$vux.toast.show({
          type: "text",
          text: "身份证照片无法正确解析，请返回上一页重拍",
          position: "default",
          time: 3000
        });
        break;
      case "INVALID_IMAGE_SIZE: image_ref1":
        dom.$vux.toast.show({
          type: "text",
          text: "身份证照片太大，请返回上一页重拍",
          position: "default",
          time: 3000
        });
        break;
      case "NO_FACE_FOUND: image_ref1":
        dom.$vux.toast.show({
          type: "text",
          text: "身份证照片没有检测到人脸，请返回上一页重拍",
          position: "default",
          time: 3000
        });
        break;
      case "MULTIPLE_FACES: image_ref1":
        dom.$vux.toast.show({
          type: "text",
          text: "身份证照片检测到多张人脸，请返回上一页重拍",
          position: "default",
          time: 3000
        });
        break;
      case "INVALID_NAME_FORMAT":
        dom.$vux.toast.show({
          type: "text",
          text: "真实姓名不正确，请返回上一页，拍摄本人真实身份证照片",
          position: "default",
          time: 3000
        });
        break;
      case "INVALID_IDCARD_NUMBER":
        dom.$vux.toast.show({
          type: "text",
          text: "身份证号码格式不正确，请返回上一页，拍摄本人真实身份证照片",
          position: "default",
          time: 3000
        });
        break;
      case "NO_SUCH_ID_NUMBER":
        dom.$vux.toast.show({
          type: "text",
          text: "身份证号码不存在，请返回上一页，拍摄本人真实身份证照片",
          position: "default",
          time: 3000
        });
        break;
      case "ID_NUMBER_NAME_NOT_MATCH":
        dom.$vux.toast.show({
          type: "text",
          text:
            "身份证号码与提供的姓名不匹配，请返回上一页，拍摄本人真实身份证照片",
          position: "default",
          time: 3000
        });
        break;
      case "DATA_SOURCE_ERROR":
        dom.$toast("系统异常，请稍后重试");
        break;
      // 通用
      case "AUTHENTICATION_ERROR":
        dom.$toast("系统异常，请稍后重试");
        break;
      case "CONCURRENCY_LIMIT_EXCEEDED":
        dom.$toast("系统异常，请稍后重试");
        break;
      case "MISSING_ARGUMENTS":
        dom.$toast("系统异常，请稍后重试");
        break;
      case "BAD_ARGUMENTS":
        dom.$toast("系统异常，请稍后重试");
        break;
      case "API_NOT_FOUND":
        dom.$toast("系统异常，请稍后重试");
        break;
      case "INTERNAL_ERROR":
        dom.$toast("系统异常，请稍后重试");
        break;
      default:
        dom.$toast("系统异常，请稍后重试");
        break;
    }
  },
  // 身份证当前页错误码
  IDNowErrorMessage (data, dom) {
    switch (data) {
      case "MASK":
        dom.$toast("系统认为是假脸");
        break;
      case "SCREEN_REPLAY":
        dom.$toast("屏幕翻拍");
        break;
      case "FACEGEN":
        dom.$toast("软件合成脸");
        break;
      case "SELFIE_INCONSISTENT":
        dom.$toast("正脸侧脸照不一致");
        break;
      case "SELFIE_METADATA_INCONSISTENT":
        dom.$toast("正侧脸照的照片不都为JPG编码、或者元信息不一致");
        break;
      case "VIDEO_SR_ERROR":
        dom.$toast("语音识别结果与要求不符");
        break;
      case "VIDEO_NOT_SYNCHRONIZED":
        dom.$toast("视频中唇语识别错误");
        break;
      case "VIDEO_FACE_INCONSISTENT":
        dom.$toast("视频过程中的人脸不一致");
        break;
      case "LOW_QUALITY":
        dom.$toast("照片像素过低无法识别，请重新拍摄");
        break;
      // Raw-Verify
      case "INVALID_TOKEN":
        dom.$toast("系统异常，请稍后重试");
        break;
      case "IMAGE_ERROR_UNSUPPORTED_FORMAT: image_ref1":
        dom.$vux.toast.show({
          type: "text",
          text: "身份证照片有误",
          position: "default",
          time: 3000
        });
        break;
      case "INVALID_IMAGE_SIZE: image_ref1":
        dom.$vux.toast.show({
          type: "text",
          text: "图片文件过大，请重新拍摄",
          position: "default",
          time: 3000
        });
        break;
      case "INVALID_IMAGE_SIZE: image":
        dom.$vux.toast.show({
          type: "text",
          text: "图片文件过大，请重新拍摄",
          position: "default",
          time: 3000
        });
        break;
      case "NO_FACE_FOUND: image_ref1":
        dom.$vux.toast.show({
          type: "text",
          text: "照片无法识别，请确保证件清晰可见，四角完整",
          position: "default",
          time: 3000
        });
        break;
      case "NO_FACE_FOUND: image":
        dom.$vux.toast.show({
          type: "text",
          text: "照片无法识别，请确保证件清晰可见，四角完整",
          position: "default",
          time: 3000
        });
        break;
      case "MULTIPLE_FACES: image_ref1":
        dom.$vux.toast.show({
          type: "text",
          text: "检测到多张人脸，请重新拍摄",
          position: "default",
          time: 3000
        });
        break;
      case "MULTIPLE_FACES: image":
        dom.$vux.toast.show({
          type: "text",
          text: "检测到多张人脸，请重新拍摄",
          position: "default",
          time: 3000
        });
        break;
      case "INVALID_NAME_FORMAT":
        dom.$vux.toast.show({
          type: "text",
          text: "身份证号或姓名不正确，请重新拍摄",
          position: "default",
          time: 3000
        });
        break;
      case "INVALID_IDCARD_NUMBER":
        dom.$vux.toast.show({
          type: "text",
          text: "身份证号不正确，请重新拍摄",
          position: "default",
          time: 3000
        });
        break;
      case "NO_SUCH_ID_NUMBER":
        dom.$vux.toast.show({
          type: "text",
          text: "身份证号码有误",
          position: "default",
          time: 3000
        });
        break;
      case "ID_NUMBER_NAME_NOT_MATCH":
        dom.$vux.toast.show({
          type: "text",
          text: "您的身份证号与姓名有误",
          position: "default",
          time: 3000
        });
        break;
      case "DATA_SOURCE_ERROR":
        dom.$toast("系统异常，请稍后重试");
        break;
      // 通用
      case "AUTHENTICATION_ERROR":
        dom.$toast("系统异常，请稍后重试");
        break;
      case "CONCURRENCY_LIMIT_EXCEEDED":
        dom.$toast("系统异常，请稍后重试");
        break;
      case "MISSING_ARGUMENTS":
        dom.$toast("系统异常，请稍后重试");
        break;
      case "BAD_ARGUMENTS":
        dom.$toast("系统异常，请稍后重试");
        break;
      case "API_NOT_FOUND":
        dom.$toast("系统异常，请稍后重试");
        break;
      case "INTERNAL_ERROR":
        dom.$toast("系统异常，请稍后重试");
        break;
      default:
        dom.$toast("系统异常，请稍后重试");
        break;
    }
  },
  PhoneCheck (phonenum) {
    return /^1\d{10}$/.test(phonenum)
  }
};

