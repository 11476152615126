import axios from './request'
import qs from 'querystring'
import common from './common'
console.log(process.env.NODE_ENV)

// 测试环境
export var businessApi = '/product/business'
export var datainnerApi ='/product/datainner'//第3方
export var adminApi = '/product/adminH5'

//本地
// export var businessApi = '/business'
// export var datainnerApi ='/datainner'
// export var adminApi = '/adminH5'

if(process.env.NODE_ENV == 'development'){
  // api = '/admin'
}
if(process.env.NODE_ENV == 'production'){
}
export var newsImgBaseUrl =  (process.env.NODE_ENV == 'production') ? (window.location.origin + '/file'):("https://h5-test.cunkouyizhan.cn/file")
export var bigDataImgBaseUrl =  (process.env.NODE_ENV == 'production') ? (window.location.origin ):("https://h5-test.cunkouyizhan.cn/")

export var wechatGZH = 'https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=Mzg5NjY0ODY3NQ==&scene=110#wechat_redirect'
// export var wechatGZH = (process.env.NODE_ENV == 'production') ? ('https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=Mzg5NjY0ODY3NQ==&scene=110#wechat_redirect'):('https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzU4ODcyMjg2Nw==&scene=124#wechat_redirect')

// 获取openid
export var fetchOpenid = (params) => {
  return axios({
    url: `${businessApi}/wechat/getWechatUserInfo`,
    data: sign(params),
    method: 'POST'
  })
}

// 首页获取轮播图、用户状态等信息
export var getHomeInfo = () => {
  return axios({
    url: `${businessApi}/home/getHomeInfo`,
    method: 'POST',
    data:sign({
      uniqueNo: common.getItem('uniqueNo') ? common.getItem('uniqueNo') : '',
      platform: 'H5'
    })
  })
}

// 查询我的账本，返回账本列表
export var getAccountBook = (params) => {
  return axios({
    url: `${businessApi}/account/getAccountBook`,
    method: 'POST',
    data:sign(params)
  })
}

// 查询我的客户，返回客户列表
export var getCustomerList = (params) => {
  return axios({
    url: `${businessApi}/customer/getCustomerList`,
    method: 'POST',
    data:sign(params)
  })
}

// 保存客户信息（新建、修改）
export var saveCustomerInfo = (params) => {
  return axios({
    url: `${businessApi}/customer/saveCustomerInfo`,
    method: 'POST',
    data:sign(params)
  })
}

// 查询客户信息，返回单个客户信息
export var getCustomerInfo = (params) => {
  return axios({
    url: `${businessApi}/customer/getCustomerInfo`,
    method: 'POST',
    data:sign(params)
  })
}

// 记一笔信息（新建、修改）
export var chargeAccount = (params) => {
  return axios({
    url: `${businessApi}/account/chargeAccount`,
    method: 'POST',
    data:sign(params)
  })
}

// 记一笔信息（删除）
export var deleteAccount = (params) => {
  return axios({
    url: `${businessApi}/account/deleteAccount`,
    method: 'POST',
    data:sign(params)
  })
}

// 查询查询账本信息
export var getAccountInfo = (params) => {
  return axios({
    url: `${businessApi}/account/getAccountInfo`,
    method: 'POST',
    data:sign(params)
  })
}

// 获取短信验证码
export var getSmsMessage = (params) => {
  return axios({
    url: `${businessApi}/login/getSmsMessage`,
    method: 'POST',
    data: sign(params)
  })
}

// 校验短信验证码
export var checkSmsMessage = (params) => {
  return axios({
    url: `${businessApi}/login/checkSmsMessage`,
    method: 'POST',
    data: sign(params)
  })
}

// 退出登录
export var logout = (params) => {
  return axios({
    url: `${businessApi}/login/logout`,
    method: 'POST',
    data: sign(params)
  })
}

export var saveYouzanOrder = (params) => {
  return axios({
    url: `${businessApi}/youzan/saveYouzanOrder`,
    method: 'POST',
    data: sign(params)
  })
}

// 根据协议代码获取协议信息（通用）
/*
  协议代码：
  AGT001：《注册协议》 /agreement/registerProtocol
  AGT002：《隐私政策》  /agreement/privacyPolicy
  AGT003：《村口壹站农村普惠金融服务合作协议》  /agreement/signBankProtocol
  AGT004：《特约商户协议》 /agreement/sepcialBusinessProtocol
  AGT005：《站长管理办法》  /agreement/stationAgentProtocol
  AGT006：《分包协议（个人经营者）》  /agreement/subpackageProtocol
  AGT007：《身份授权协议》  /agreement/identityProtocol
  AGT008：《合规经营承诺书》/agreement/supplementProtocol
  AGT009: 《村口壹站加盟合作协议》 /agreement/cooperationFrameworkProtocol
*/
export var getAgreementInfoByType = (params) => {
  return axios({
    url: `${businessApi}/agreement/getAgreementInfoByType`,
    method: 'POST',
    data: sign(params)
  })
}
export var getAgreementNoToken = (params) => {
  return axios({
    url: `${businessApi}/agreement/getAgreementNoToken`,
    method: 'POST',
    data: sign(params)
  })
}

// 我的协议（站长）
export var getAgreementList = (params) => {
  return axios({
    url: `${businessApi}/agreement/getAgreementList`,
    method: 'POST',
    data: sign(params)
  })
}

// 根据协议代码和版本获取协议信息（通用）
export var getAgreementInfoByVersion = (params) => {
  return axios({
    url: `${businessApi}/agreement/getAgreementInfoByVersion`,
    method: 'POST',
    data: params
  })
}

//0-10000的随机数
function random() {
  var num = Math.floor(Math.random() * 10000 + 1)
  return num
}

export var sign = data => {
  if (common.getItem('wtToken')){
    data.token =  common.getItem('wtToken')
  }
   // data.token = '19c3f4700e13492dbd86c894f762128d'//测试使用
  var timestamp = new Date().getTime()
  var secretKey = 'N==KNT|QJZN2019'
  var requestId = timestamp + '' + random()
  var version = 0
  var sign =
    'version=' +
    version +
    '&timestamp=' +
    timestamp +
    '&secretKey=' +
    secretKey +
    '&requestId=' +
    requestId +
    '&platform=H5' +
    '&encryptedStatus=0' +
    '&data=' +
    JSON.stringify(data)
  sign = md5(sign)
  return {
    data: JSON.stringify(data),
    sign: sign,
    timestamp: timestamp,
    requestId: requestId,
    version: version,
    encryptedStatus: 0,
    platform: 'H5'
  }
}

// 获取省市查询信息
export var getAreaInfoProvDict = () => {
  return axios({
    url: `${businessApi}/area/getAreaInfoProvDict`,
    method: 'GET'
  })
}
// 获取村镇联动信息
export var getAreaInfoCountyDict = (params) => {
  return axios({
    url: `${businessApi}/area/getAreaInfoCountyDict?countyNo=${params}`,
    method: 'GET'
  })
}
// 根据村级行政码获取村介绍详情
export var getVillageInfoByAreaCode = (params) => {
  return axios({
    url: `${businessApi}/area/getVillageInfoByAreaCode?areaCode=${params}`,
    method: 'GET'
  })
}

// form 请求
export var healthNotifiForm = (urlData, params) => {
  return axios({
    url: urlData,
    method: 'POST',
    data: qs.stringify(params)
  })
}

export var saveStationInfo = (params) => {
  return axios({
    url: `${businessApi}/station/saveStationInfo`,
    method: 'POST',
    data: sign(params)
  })
}
export var saveMasterInfo = (params) => {
  return axios({
    url: `${businessApi}/master/saveMasterInfo`,
    method: 'POST',
    data: sign(params)
  })
}
export var getDictByPage = (params) => {
  var  token = ''
  if (common.getItem('wtToken')){
    token = common.getItem('wtToken');
  }
  return axios({
    url: `${businessApi}/dict/getDictByPage/${params}?token=${token}`,
    method: 'GET'
  })
}
export var getStationInfoByCode = (params) => {
  return axios({
    url: `${businessApi}/station/getStationInfoByCode`,
    data: sign(params),
    method: 'POST'
  })
}
export var getMasterInfoByIdCard = (params) => {
  return axios({
    url: `${businessApi}/master/getMasterInfoByIdCard`,
    method: 'POST',
    data: sign(params)
  })
}

// 查询参与者列表
export var getParterByDeptNo = (params) => {
  return axios({
    url: `${businessApi}/station/getParterByDeptNo`,
    method: 'POST',
    data: sign(params)
  })
}

// 查询合作银行列表
export var getBankList = (params) => {
  var token = '';
  if (common.getItem('wtToken')){
    token =  common.getItem('wtToken')
  }
  return axios({
    url: `${businessApi}/bank/getBankList?token=${token}`,
    method: 'GET',
  })
}

// 提交加盟
export var submitJoin = (params) => {
  return axios({
    url: `${businessApi}/station/submitJoin`,
    method: 'POST',
    data: sign(params)
  })
}

// 上传图片接口
export var saveFileInfo = (params) => {
  return axios({
    url: `${businessApi}/common/saveFileInfo`,
    method: 'POST',
    data: sign(params)
  })
}

// 删除图片
export var deleteFileByFileNo = (params) => {
  return axios({
    url: `${businessApi}/common/deleteFileByFileNo`,
    method: 'POST',
    data: sign(params)
  })
}

// 查询待审批站点
export var getPendingStationList = (params) => {
  return axios({
    url: `${businessApi}/approve/getPendingStationList`,
    method: 'POST',
    data: sign(params)
  })
}

// 查询我的站点（市场人员）
export var marketerMySite = (params) => {
  return axios({
    url: `${businessApi}/mySite/marketerMySite`,
    method: 'POST',
    data: sign(params)
  })
}

// 市场人员分页查询我的站点
export var getMySitePage = (params) => {
  return axios({
    url: `${businessApi}/mySite/getMySitePage`,
    method: 'POST',
    data: sign(params)
  })
}

// 查询站点信息模块
export var getStationOutlineByNo = (params) => {
  return axios({
    url: `${businessApi}/station/getStationOutlineByNo`,
    method: 'POST',
    data: sign(params)
  })
}

// 根据站长编号查询站长信息
export var getMasterInfoByNo = (params) => {
  return axios({
    url: `${businessApi}/master/getMasterInfoByNo`,
    method: 'POST',
    data: sign(params)
  })
}

// 根据站长编号查询脱敏站长信息
export var getDesenseMasterInfoByNo = (params) => {
  return axios({
    url: `${businessApi}/master/getDesenseMasterInfoByNo`,
    method: 'POST',
    data: sign(params)
  })
}
// 根据站点编号查询村情基本信息
export var getStationBaseInfo = (params) => {
  var token = '';
  if (common.getItem('wtToken')){
    token =  common.getItem('wtToken')
  }
  return axios({
    url: `${businessApi}/station/getStationBaseInfo/?stationNo=${params}&token=${token}`,
    method: 'GET'
  })
}
// 根据站点编号查询村情调查信息
export var getSurveyItems = (params) => {
  var token = '';
  if (common.getItem('wtToken')){
    token =  common.getItem('wtToken')
  }
  return axios({
    url: `${businessApi}/station/getSurveyItems/?stationNo=${params}&token=${token}`,
    method: 'GET'
  })
}

// 站点初审
export var completeStep = (params) => {
  return axios({
    url: `${businessApi}/approve/completeStep`,
    method: 'POST',
    data: sign(params)
  })
}
// 判断该身份证号是否已经申请建站
export var getIdCardExist = (params) => {
  return axios({
    url: `${businessApi}/master/getIdCardExist`,
    method: 'POST',
    data: sign(params)
  })
}


// 我的组织
export var getMyOrg = (params) => {
  return axios({
    url: `${businessApi}/org/getMyOrg`,
    method: 'POST',
    data: sign(params)
  })
}

// 上传身份证图片
export var sendOcrPic = (params) => {
  return axios({
    url: `${businessApi}/face/sendOcrPicH5`,
    method: 'POST',
    data: sign(params)
  })
}// 保存身份证图片
export var idCardOcrFront = (params) => {//新建站点站长识别身份证号
  return axios({
    url: `${businessApi}/face/idCardOcrFront`,
    method: 'POST',
    data: sign(params)
  })
}
export var saveIdentityInfo = (params) => {
  return axios({
    url: `${businessApi}/master/saveIdentityInfo`,
    method: 'POST',
    data: sign(params)
  })
}
//营业执照ocr
export var sendBusinessLicenseOcr = (params) => {
  return axios({
    url: `${businessApi}/face/businessLicenseOcr`,
    method: 'POST',
    data: sign(params)
  })
}

// 保存营业执照信息
export var saveBusinessLicenseInfo = (params) => {
  return axios({
    url: `${businessApi}/master/saveBusinessLicenseInfo`,
    method: 'POST',
    data: sign(params)
  })
}


//银行卡ocr
export var sendBankCardOcr = (params) => {
  return axios({
    url: `${businessApi}/face/sendBankCardOcr`,
    method: 'POST',
    data: sign(params)
  })
}
export var driverLicenOcr = (params) => {
  return axios({
    url: `${businessApi}/face/driverLicenseOcr`,
    method: 'POST',
    data: sign(params)
  })
}

//获取银行
export var checkATDCard = (params) => {
  return axios({
    url: `${businessApi}/face/checkATDCardNo`,
    method: 'POST',
    data: sign(params)
  })
}
//提交认证
export var submitCertification = (params) => {
  return axios({
    url: `${businessApi}/master/submitCertification`,
    method: 'POST',
    data: sign(params)
  })
}
//读取图片
export var getPic = (url) => {
  return axios({
    url: '/file/'+url,
    // url: '/file/group1/M00/00/00/rBcqh158Ji6ATBykAAA_Lby1_HM35.jpeg',
    method: 'get',
    headers:{
      Authorization: common.getItem('wtToken')
    }
  })
}

// 查询站点（市场 人员）
export var getSiteByStationNo = (params) => {
  return axios({
    url: `${businessApi}/station/getSiteByStationNo`,
    method: 'POST',
    data: sign(params)
  })
}

export var getSXRInfoByStationNo = (params) => {
  return axios({
    url: `${businessApi}/SXR/getSXRInfoByStationNo`,
    method: 'POST',
    data: sign(params)
  })
}

export var getPendingStationByStationNo = (params) => {//查询待审核站点数据
  return axios({
    url: `${businessApi}/approve/getPendingStationByStationNo`,
    method: 'POST',
    data: sign(params)
  })
}

// 站长签约
export var signContract = (params) => {
  return axios({
    url: `${businessApi}/master/signContract`,
    method: 'POST',
    data: sign(params)
  })
}

// 查询站点（站长）
export var getSiteByMasterNo = (params) => {
  return axios({
    url: `${businessApi}/station/getSiteByMasterNo`,
    method: 'POST',
    data: sign(params)
  })
}

// 获取我的页面信息
export var getMineInfo = (params) => {
  return axios({
    url: `${businessApi}/mine/getMineInfo`,
    method: 'POST',
    data: sign(params)
  })
}

// 我的银行卡
export var getUserBankByNo = (params) => {
  return axios({
    url: `${businessApi}/master/getUserBankByNo`,
    method: 'POST',
    data: sign(params)
  })
}

// 我的保障-查询站长保单列表信息
export var getInsuranceInfo = (params) => {
  return axios({
    url: `${adminApi}/insurance/getInsuranceInfo`,
    method: 'POST',
    data: sign(params)
  })
}

// 根据图片类型和编号获取图片
export var getPicByTypeAndNo = (params) => {
  return axios({
    url: `${businessApi}/common/getPicByTypeAndNo`,
    method: 'POST',
    data: sign(params)
  })
}

// 查询电子合同信息
export var getContractByNo = (params) => {
  return axios({
    url: `${businessApi}/agreement/getContractByNo`,
    method: 'POST',
    data: sign(params)
  })
}

// 获取业绩信息
export var getPerformance = (params) => {
  var  token = ''
  if (common.getItem('wtToken')){
    token = common.getItem('wtToken');
  }
  return axios({
    url: `${businessApi}/home/getPerformance/?stationNo=${params}&token=${token}`,
    method: 'GET'
  })
}

// 获取业绩统计明细
export var getPerformanceDetail = (params) => {
  return axios({
    url: `${businessApi}/home/getPerformanceDetail`,
    method: 'POST',
    data: sign(params)
  })
}


// 获取资讯信息列表
export var getInformationListForH5 = (params) => {
  return axios({
    url: `${adminApi}/information/getInformationListForH5`,
    method: 'POST',
    data: sign(params)
  })
}

// 获取资讯信息详情
export var getInformationDetails = (params) => {
  return axios({
    url: `${adminApi}/information/getInformationDetails`,
    method: 'POST',
    data: sign(params)
  })
}


// 获取美丽乡村配置项
export var getPageElementBeatifulVillage = () => {
  return axios({
    url: `${businessApi}/common/getPageElement?page=beatifulVillage`,
    method: 'GET'
  })
}




// 获取首页便捷入口跳转链接
export var getHomeQuickDoorUrl = (params) => {
  return axios({
    url: `${datainnerApi}/ofpay/getUrl`,
    method: 'POST',
    data: sign(params)
  })
}


// 获取我的任务列表
export var getMyTaskList = (params) => {
  return axios({
    url: `${businessApi}/task/getMyTaskList`,
    method: 'POST',
    data: sign(params)
  })
}

// 获取我的任务详情
export var getMyTask = (params) => {
  return axios({
    url: `${businessApi}/task/getMyTask`,
    method: 'POST',
    data: sign(params)
  })
}

// 保存我的任务
export var saveMyTask = (params) => {
  return axios({
    url: `${businessApi}/task/saveMyTask`,
    method: 'POST',
    data: sign(params)
  })
}
export var addInspectTask = (params) => {
  return axios({
    url: `${businessApi}/task/addInspectTask`,
    method: 'POST',
    data: sign(params)
  })
}

// 获取验证码图片
export var getGraphCode=(params)=>{
  return axios({
    url:`${businessApi}/validateCode/getGraphCode`,
    method:'Post',
    data:sign(params)
  })
}

// 上传图片压缩包
export var uploadZipFile=(uploadFile,stationNo)=>{
  var formData = new FormData()
  formData.append('uploadFile', uploadFile)
  formData.append('stationNo', stationNo)
  if (common.getItem('wtToken')){
    formData.append('token', common.getItem('wtToken'))
  }
  return axios({
    url:`${businessApi}/common/zipFile`,
    method:'Post',
    data: formData,
    config: {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  })
}

export const uploadZipAndAlreadyImg=(uploadFile,stationNo,fileUrl)=>{ //上传本地压缩图片参数带已有网络图片
  const formData = new FormData()
  formData.append('uploadFile', uploadFile)
  if (stationNo){
    formData.append('stationNo', stationNo)
  }
if (fileUrl){
  formData.append('fileUrlMap',fileUrl)
}


  if (common.getItem('wtToken')){
    formData.append('token', common.getItem('wtToken'))
  }
  return axios({
    url:`${businessApi}/common/zipFile`,
    method:'Post',
    data: formData,
    config: {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
  })
}
// 绑定设备编号
export var bindEquipment=(params)=>{
  return axios({
    url:`${businessApi}/equipment/bindEquipment`,
    method:'Post',
    data:sign(params)
  })
}


// 天气实况查询
export var getCondition=(params)=>{
  return axios({
    url:`${datainnerApi}/weather/getCondition`,
    method:'Post',
    data:sign(params)
  })
}


// 查询站点视频通道
export var getDeviceChannels=(params)=>{
  return axios({
    url:`${businessApi}/channel/getDeviceChannels`,
    method:'Post',
    data:sign(params)
  })
}

// 查询视频通道播放地址
export var getLiveAddress=(params)=>{
  return axios({
    url:`${datainnerApi}/hik/channel/liveAddress`,
    method:'Post',
    data:sign(params)
  })
}
// 意见反馈
export var insertUserSugges=(params)=>{
  return axios({
    url:`${businessApi}/suggest/insertUserSuggest`,
    method:'Post',
    data:sign(params)
  })
}

// 站点部门业绩
export var getDeptPerformance=(params)=>{
  return axios({
    url:`${businessApi}/mySite/getDeptPerformance`,
    method:'Post',
    data:sign(params)
  })
}

// 获取下载链接地址和版本号
export var getVersionInfo=(params)=>{
  return axios({
    url:`${adminApi}/version/getVersionInfo`,
    method:'Post',
    data:sign(params)
  })
}

// 查看下级部门列表
export var getLowerDeptList=(params)=>{
  return axios({
    url:`${businessApi}/org/getLowerDeptList`,
    method:'Post',
    data:sign(params)
  })
}

// 查询部门各期业绩
export var getPerPeriodPerformanceList=(params)=>{
  return axios({
    url:`${businessApi}/mySite/getPerPeriodPerformanceList`,
    method:'Post',
    data:sign(params)
  })
}

// 获取子任务列表
export var getSubTaskList=(params)=>{
  return axios({
    url:`${businessApi}/task/getSubTaskList`,
    method:'Post',
    data:sign(params)
  })
}

// 获取子任务详情
export var getSubTaskDetail=(params)=>{
  return axios({
    url:`${businessApi}/task/getSubTaskDetail`,
    method:'Post',
    data:sign(params)
  })
}

export var getPermissions=(params)=>{
  return axios({
    url:`${businessApi}/userBase/getPermissions`,
    method:'Post',
    data:sign(params)
  })
}

export var getOutSStationDetails=(params)=>{
  return axios({
    url:`${adminApi}/excellent/getStationDetails?id=${params.id}`,
    method:'get',
  })
}
export var getExpressListForH5=(params)=>{
  return axios({
    url:`${adminApi}/kbexpress/getExpressListForH5`,
    method:'post',
    data:sign(params)
  })
}

export var getExpressStationApplyNo=(params)=>{//获取快递加盟申请任务编号
  return axios({
    url:`${businessApi}/express/getExpressStationApplyNo`,
    method:'post',
    data:sign(params)
  })
}
export var getSubExpressList=(params)=>{//获取快递申请子任务列表
  return axios({
    url:`${businessApi}/express/getSubExpressList`,
    method:'post',
    data:sign(params)
  })
}
export var getSubExpressDetail=(params)=>{//获取快递申请子任务详情
  return axios({
    url:`${businessApi}/express/getSubExpressDetail`,
    method:'post',
    data:sign(params)
  })
}
export var saveSubExpress=(params)=>{//保存快递站点子任务
  return axios({
    url:`${businessApi}/express/saveSubExpress`,
    method:'post',
    data:sign(params)
  })
}
export var submitExpressApply=(params)=>{//提交快递站点加盟申请
  return axios({
    url:`${businessApi}/express/submitExpressApply`,
    method:'post',
    data:sign(params)
  })
}
// 查询站点各期业绩
export var getStationPerPeriodPerformanceList=(params)=>{//提交快递站点加盟申请
  return axios({
    url:`${businessApi}/mySite/getStationPerPeriodPerformanceList`,
    method:'post',
    data:sign(params)
  })
}
export var getWXPara=(params)=>{//微信签名
  return axios({
    url:`${businessApi}/wechat/share`,
    method:'post',
    data:sign(params)
  })
}
export var getMetabaseUrl=(params)=>{//metabase url获取
  return axios({
    url:`${businessApi}/home/getMetabaseUrl`,
    method:'get',

  })
}
// 查询部门组织结构
export var getMyDeptTree=(params)=>{
  return axios({
    url:`${businessApi}/org/getMyDeptTree`,
    method:'post',
    data:sign(params)
  })
}

export var getSubTaskTemp=(params)=>{//获取子任务模板
  return axios({
    url:`${businessApi}/task/getSubTaskTemp`,
    method:'post',
    data:sign(params)
  })
}
export var getProbeTaskData=(params)=>{//获取任务数据
  return axios({
    url:`${businessApi}/task/selectSubTaskDetail`,
    method:'post',
    data:sign(params)
  })
}
export var saveSubTaskDetail=(params)=>{//保存走访调研子任务
  return axios({
    url:`${businessApi}/task/saveSubTaskDetail`,
    method:'post',
    data:sign(params)
  })
}
export var saveProbeTask=(params)=>{//保存走访调研任务
  return axios({
    url:`${businessApi}/probe/saveProbeTask`,
    method:'post',
    data:sign(params)
  })
}
export var createProbeTask=(params)=>{//创建走访调研任务taskNo
  return axios({
    url:`${businessApi}/probe/createProbeTask`,
    method:'post',
    data:sign(params)
  })
}

// 获取走访调研任务列表
export var getProbeTaskList=(params)=>{//
  return axios({
    url:`${businessApi}/probe/getProbeTaskList`,
    method:'post',
    data:sign(params)
  })
}

export var getTalentList=(params)=>{//
  return axios({
    url:`${businessApi}/tiktokTalent/getTalentList`,
    method:'post',
    data:sign(params)
  })
}
export var getAuthorizationLinkInfo=(params)=>{//
  return axios({
    url:`${businessApi}/tiktokTalent/getAuthorizationLinkInfo`,
    method:'post',
    data:sign(params)
  })
}

export var viewTalentInfo=(params)=>{//
  return axios({
    url:`${businessApi}/tiktokTalent/viewTalentInfo`,
    method:'post',
    data:sign(params)
  })
}

export var getTalentNewsList=(params)=>{//
  return axios({
    url:`${adminApi}/talentNews/getTalentNewsList`,
    method:'post',
    data:sign(params)
  })
}

// 获取子任务列表
export var getprobeSubTaskList=(params)=>{
  return axios({
    url:`${businessApi}/probe/getSubTaskList`,
    method:'Post',
    data:sign(params)
  })
}
export var selectSubTaskList=(params)=>{//taskNo获取子任务列表
  return axios({
    url:`${businessApi}/task/selectSubTaskList`,
    method:'Post',
    data:sign(params)
  })
}

export var getInspectList = (params) => {
  return axios({
    url: `${businessApi}/task/getInspectList`,
    method: 'POST',
    data: sign(params)
  })
}

export var getInspectDetail = (params) => {
  return axios({
    url: `${businessApi}/task/getInspectDetail`,
    method: 'POST',
    data: sign(params)
  })
}

export var getEquipmentDeposit=(params)=>{//设备押金信息查询
  return axios({
    url:`${businessApi}/deposit/getEquipmentDeposit`,
    method:'Post',
    data:sign(params)
  })
}
export var saveDepositInfo=(params)=>{//缴纳设备押金保存
  return axios({
    url:`${businessApi}/deposit/saveDepositInfo`,
    method:'Post',
    data:sign(params)
  })
}

export var saveSiteFiles=(params)=>{//保存站点管理档案
  return axios({
    url:`${businessApi}/station/saveSiteFiles`,
    method:'Post',
    data:sign(params)
  })
}

export var getSiteFileData=(params)=>{//查询站点档案
  return axios({
    url:`${businessApi}/station/getSiteFileData`,
    method:'Post',
    data:sign(params)
  })
}

// 天气预报6天查询
export var getCondition6Days=(params)=>{
  return axios({
    url:`${datainnerApi}/weather/getCondition6Days`,
    method:'post',
    data:sign(params)
  })
}

// 农产品价格查询
export var getProductPrice=(params)=>{
  return axios({
    url:`${datainnerApi}/farm/getProductPrice`,
    method:'post',
    data:sign(params)
  })
}

// 乡村新闻查询
export var getNews=(params)=>{
  return axios({
    url:`${datainnerApi}/news/getNews`,
    method:'post',
    data:sign(params)
  })
}

// 乡村新闻正文查询
export var getNewsInfo=(params)=>{
  return axios({
    url:`${datainnerApi}/news/getNewsInfo`,
    method:'post',
    data:sign(params)
  })
}

// 查询合作银行三级联动
export var getBranchList = (params) => {
  var token = '';
  if (common.getItem('wtToken')){
    token =  common.getItem('wtToken')
  }
  return axios({
    url: `${businessApi}/bank/getBranchList?token=${token}`,
    method: 'GET'
  })
}

// 已上线站点列表
export var getOnlineStationList=(params)=>{
  return axios({
    url:`${businessApi}/mySite/getOnlineStationList`,
    method:'post',
    data:sign(params)
  })
}

//根据站点编号获取协议列表
export var getAgreementListByStationNo = (params) => {
  return axios({
    url: `${businessApi}/agreement/getAgreementListByStationNo`,
    method: 'POST',
    data: sign(params)
  })
}

// 站长最新收入情况查询
export var getMasterIncome = (params) => {
  return axios({
    url: `${adminApi}/settlement/getMasterIncome`,
    method: 'POST',
    data: sign(params)
  })
}
//查询历史收益详情
export var getIncomeList = (params) => {
  return axios({
    url: `${adminApi}/settlement/getIncomeList`,
    method: 'POST',
    data: sign(params)
  })
}
//站长收入情况确认
export var confirmIncome = (params) => {
  return axios({
    url: `${adminApi}/settlement/confirmIncome`,
    method: 'POST',
    data: sign(params)
  })
}
// 查询收入信息
export var getSumData = (params) => {
  return axios({
    url: `${adminApi}/performance/getSumData`,
    method: 'POST',
    data: sign(params)
  })
}

// 加盟申请
export var joinApply = (params) => {
  return axios({
    url: `${businessApi}/master2/joinApply`,
    method: 'POST',
    data: sign(params)
  })
}

// 加盟申请查询
export var getJoinApply = (params) => {
  return axios({
    url: `${businessApi}/master2/getJoinApply`,
    method: 'POST',
    data: sign(params)
  })
}

// 保存站点名称
export var saveStationName = (params) => {
  return axios({
    url: `${businessApi}/station2/saveStationName`,
    method: 'POST',
    data: sign(params)
  })
}

// 上传图片接口（站长签名专用）
export var saveSignFile = (params) => {
  return axios({
    url: `${businessApi}/common/saveSignFile`,
    method: 'POST',
    data: sign(params)
  })
}

// 上传图片接口（站长签名专用）
export var getJoinInfoByStationNo = (params) => {
  return axios({
    url: `${businessApi}/station2/getJoinInfoByStationNo`,
    method: 'POST',
    data: sign(params)
  })
}

// 暂存村情信息
export var tempVillageInfo = (params) => {
  return axios({
    url: `${businessApi}/station2/tempVillageInfo`,
    method: 'POST',
    data: sign(params)
  })
}

export var saveVillageInfo = (params) => {
  return axios({
    url: `${businessApi}/station2/saveVillageInfo`,
    method: 'POST',
    data: sign(params)
  })
}

export var updStationRiskTag = (params) => {
  return axios({
    url: `${businessApi}/admin/updStationRiskTag`,
    method: 'POST',
    data: sign(params)
  })
}

// 暂存站长信息
export var tempMasterInfo = (params) => {
  return axios({
    url: `${businessApi}/master2/tempMasterInfo`,
    method: 'POST',
    data: sign(params)
  })
}

// 保存站长信息
export var saveMasterInfo2 = (params) => {
  return axios({
    url: `${businessApi}/master2/saveMasterInfo`,
    method: 'POST',
    data: sign(params)
  })
}

// 查询站点保存详情（反显使用）
export var getStationCaseByNo = (params) => {
  return axios({
    url: `${businessApi}/station2/getStationCaseByNo`,
    method: 'POST',
    data: sign(params)
  })
}

// 查询村情信息（反显使用）
export var getVillageInfoByNo = (params) => {
  return axios({
    url: `${businessApi}/station2/getVillageInfoByNo`,
    method: 'POST',
    data: sign(params)
  })
}

// 查询站长信息（反显使用）
export var getMasterInfoByNo2 = (params) => {
  return axios({
    url: `${businessApi}/master2/getMasterInfoByNo`,
    method: 'POST',
    data: sign(params)
  })
}

// 查询市场人员可查站点地区结构
export var getMyAreaTree = (params) => {
  return axios({
    url: `${businessApi}/area/getMyAreaTree`,
    method: 'POST',
    data: sign(params)
  })
}

// 根据条件查询站点（市场人员）
export var getMySitePageQuery = (params) => {
  return axios({
    url: `${businessApi}/mySite/getMySitePageQuery`,
    method: 'POST',
    data: sign(params)
  })
}


// 获取用户信息
export var getUserInfo = (params) => {
  return axios({
    url: `${businessApi}/home/getUserInfo`,
    method: 'POST',
    data: sign(params)
  })
}

// 查询运营人员所属部门列表
export var getDeptList = (params) => {
  return axios({
    url: `${businessApi}/marketer/getDeptList`,
    method: 'POST',
    data: sign(params)
  })
}

// 变更站点所属部门
export var updateStationDept = (params) => {
  return axios({
    url: `${businessApi}/station2/updateStationDept`,
    method: 'POST',
    data: sign(params)
  })
}

//查询站点所属部门列表
export var getstationDeptList = (params) => {
  return axios({
    url: `${businessApi}/station2/getDeptList`,
    method: 'POST',
    data: sign(params)
  })
}

// 查询运营人员部门、角色列表
export var getDeptRoleList = (params) => {
  return axios({
    url: `${businessApi}/marketer/getDeptRoleList`,
    method: 'POST',
    data: sign(params)
  })
}

// 获取任务统计
export var getMyTaskCount = (params) => {
  return axios({
    url: `${businessApi}/task/getMyTaskCount`,
    method: 'POST',
    data: sign(params)
  })
}

// 取得用户状态
export var getMasterHireType = (params) => {
  return axios({
    url: `${businessApi}/master/getMasterHireType`,
    method: 'POST',
    data: sign(params)
  })
}

// 人力保
export var getLangDa = (params) => {
  return axios({
    url: `${datainnerApi}/renlibao/getLangDa`,
    method: 'POST',
    data: sign(params)
  })
}

// 人力保
export var getRealStatus = (params) => {
  return axios({
    url: `${datainnerApi}/renlibao/getRealStatus`,
    method: 'POST',
    data: sign(params)
  })
}

export var getPerformanceInfo = (params) => {
  return axios({
    url: `${businessApi}/mine/getPerformanceInfo`,
    method: 'POST',
    data: sign(params)
  })
}

export var getStationMilestone = (params) => {
  return axios({
    url: `${businessApi}/station2/getStationMilestone`,
    method: 'POST',
    data: sign(params)
  })
}

export var getSumPerformance = (params) => {
  return axios({
    url: `${businessApi}/mine/getSumPerformance`,
    method: 'POST',
    data: sign(params)
  })
}

export var updateStationAlias = (params) => {
  return axios({
    url: `${businessApi}/station2/updateStationAlias`,
    method: 'POST',
    data: sign(params)
  })
}


export var getMyTaskListQuery = (params) => {
  return axios({
    url: `${businessApi}/task/getMyTaskListQuery`,
    method: 'POST',
    data: sign(params)
  })
}

export var getBenefitInfo = (params) => {
  return axios({
    url: `${businessApi}/benefit/getBenefitInfo`,
    method: 'POST',
    data: sign(params)
  })
}

export var receiveBenefit = (params) => {
  return axios({
    url: `${businessApi}/benefit/receiveBenefit`,
    method: 'POST',
    data: sign(params)
  })
}

export var getSmsMessagebenefit = (params) => {
  return axios({
    url: `${businessApi}/benefit/getSmsMessage`,
    method: 'POST',
    data: sign(params)
  })
}

export var getBenefitList = (params) => {
  return axios({
    url: `${businessApi}/benefit/getBenefitList`,
    method: 'POST',
    data: sign(params)
  })
}

export var getBenefitURL = (params) => {
  return axios({
    url: `${businessApi}/benefit/getBenefitURL`,
    method: 'POST',
    data: sign(params)
  })
}

export var getMasterIsSpecial = (params) => {
  return axios({
    url: `${businessApi}/master2/getMasterIsSpecial`,
    method: 'POST',
    data: sign(params)
  })
}


export var getMyBankTree = (params) => {
  return axios({
    url: `${businessApi}/bank/getMyBankTree`,
    method: 'POST',
    data: sign(params)
  })
}

export var getStationCompare = (params) => {
  return axios({
    url: `${businessApi}/targetComplete/getStationCompare`,
    method: 'POST',
    data: sign(params)
  })
}
// 更新站点经纬度
export var updateStation = (params) => {
  return axios({
    url: `${businessApi}/security/updateStation`,
    method: 'POST',
    data: sign(params)
  })
}
//
export var getStationInfoByNo = (params) => {
  return axios({
    url: `${businessApi}/station/getStationInfoByNo`,
    method: 'POST',
    data: sign(params)
  })
}
export var savetalentInfo = (params) => {
  return axios({
    url: `${businessApi}/tiktokTalent/savetalentInfo`,
    method: 'POST',
    data: sign(params)
  })
}

//短信发送接口
export var sendSms = (params) => {
  return axios({
    url: `${businessApi}/security/sendSms`,
    method: 'POST',
    data: sign(params)
  })
}
//获取换签合同列表
export var getReSignAgreement = (params) => {
  return axios({
    url: `${businessApi}/agreement/getReSignAgreement`,
    method: 'POST',
    data: sign(params)
  })
}

//协议换签
export var reSignContract = (params) => {
  return axios({
    url: `${businessApi}/master/reSignContract`,
    method: 'POST',
    data: sign(params)
  })
}
export var saveMasterCareTask = (params) => {
  return axios({
    url: `${businessApi}/task/saveMasterCareTask`,
    method: 'POST',
    data: sign(params)
  })
}
export var addSpecialTask = (params) => {
  return axios({
    url: `${businessApi}/task/addSpecialTask`,
    method: 'POST',
    data: sign(params)
  })
}
//营销活动详情
export var taskDetail = (params) => {
  return axios({
    url: `${businessApi}/taskMarketing/taskDetail`,
    method: 'POST',
    data: sign(params)
  })
}
//营销活动任务-保存/提交详情
export var saveOrSubmit = (params) => {
  return axios({
    url: `${businessApi}/taskMarketing/saveOrSubmit`,
    method: 'POST',
    data: sign(params)
  })
}
//营销活动任务-新增汇报
export var addReport = (params) => {
  return axios({
    url: `${businessApi}/taskMarketing/addReport`,
    method: 'POST',
    data: sign(params)
  })
}

function getRad(d){
  var PI = Math.PI;
  return d*PI/180.0;
}

/**
 * 获取两个经纬度之间的距离
 * @param lat1 第一点的纬度
 * @param lng1 第一点的经度
 * @param lat2 第二点的纬度
 * @param lng2 第二点的经度
 * @returns {Number}
 */

export function getDistance(lat1,lng1,lat2,lng2){
  var f = getRad((lat1 + lat2)/2);
  var g = getRad((lat1 - lat2)/2);
  var l = getRad((lng1 - lng2)/2);
  var sg = Math.sin(g);
  var sl = Math.sin(l);
  var sf = Math.sin(f);
  var s,c,w,r,d,h1,h2;
  var a = 6378137.0;//The Radius of eath in meter.
  var fl = 1/298.257;
  sg = sg*sg;
  sl = sl*sl;
  sf = sf*sf;
  s = sg*(1-sl) + (1-sf)*sl;
  c = (1-sg)*(1-sl) + sf*sl;
  w = Math.atan(Math.sqrt(s/c));
  r = Math.sqrt(s*c)/w;
  d = 2*w*a;
  h1 = (3*r -1)/2/c;
  h2 = (3*r +1)/2/s;
  s = d*(1 + fl*(h1*sf*(1-sg) - h2*(1-sf)*sg));
  // s = s/1000;
  s = s.toFixed(2);//指定小数点后的位数。
  return s;
}
