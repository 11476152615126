/*import 'babel-polyfill'

import Es6Promise from 'es6-promise'

Es6Promise.polyfill()

require('es6-promise').polyfill()*/


import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import qs from 'querystring'
import { callbackWechat } from '@/utils/wechat'
import { fetchOpenid } from '@/utils/api'
import common from '@/utils/common'
import echarts from 'echarts'

import {DropdownMenu, Cascader, DropdownItem,Lazyload,Swipe, SwipeItem,Uploader,
  Dialog ,Field , Icon, Popup,Toast,Area,Picker,NavBar,Cell,RadioGroup,Radio,
  CheckboxGroup,Checkbox,Button,Tag,NoticeBar,Image as VanImage,Col, Row,
  List,PullRefresh,Tab, Tabs,Search,Tabbar, TabbarItem,Overlay,Slider,Step,
  Steps ,Form, Divider, ActionSheet, DatetimePicker, Calendar, Notify ,
  Grid, GridItem
} from 'vant'
import { Switch } from 'vant';
Vue.use(Switch);
import { Circle } from 'vant';
Vue.use(Circle);
import { Progress } from 'vant';
Vue.use(Progress);
Vue.use(Cascader);
Vue.use(Lazyload);
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Uploader)
Vue.use(Dialog)
Vue.use(Toast)
Vue.use(Field)
Vue.use(Icon)
Vue.use(Popup)
Vue.use(Area)
Vue.use(Picker)
Vue.use(NavBar)
Vue.use(Cell)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(CheckboxGroup)
Vue.use(Checkbox)
Vue.use(Button)
Vue.use(Tag)
Vue.use(NoticeBar)
Vue.use(VanImage);
Vue.use(Col);
Vue.use(Row);
Vue.use(List);
Vue.use(PullRefresh);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Search);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Overlay);
Vue.use(Slider);
Vue.use(Step);
Vue.use(Steps);
Vue.use(Form);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Divider);
Vue.use(ActionSheet);
Vue.use(DatetimePicker)
Vue.use(Calendar)
Vue.use(Notify);
Vue.use(Grid);
Vue.use(GridItem);

Vue.prototype.$axios = axios
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false

import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import '@/style/common.css'
Vue.use(Element, { size: 'small', zIndex: 3000 });
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.config.productionTip = false;

Vue.use(Antd);
import FormMaking from 'form-making'
import 'form-making/dist/FormMaking.css'
Vue.use(FormMaking)
import VueAMap from  "vue-amap";
Vue.use(VueAMap)
VueAMap.initAMapApiLoader({
  key:'054ef195398749e08a72241a9d4da79e',
  plugin:['AMap.Scale', "AMap.OverView", "AMap.ToolBar", "AMap.MapType", "AMap.PlaceSearch", "AMap.Geolocation", "AMap.Geocoder"],
})
import 'default-passive-events'
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: 'bPfwzmMGzFUV7456sGctnFBiTtozXGFj'
})
import uweb from 'vue-uweb'
Vue.use(uweb,'1279710874')
console.log("window：",window.location.host)
if (window.location.host.indexOf('sit-villagestation.wowfintech.net')>-1){
  Vue.use(uweb,'1279710874')
}else{
  Vue.use(uweb,'1279759140')
}
router.beforeEach((to, from, next) => {
  store.commit('loading/CHANGE_LOADING', true)

  // 判断是否为微信环境
  let ua = navigator.userAgent.toLowerCase()
  if(ua.match(/MicroMessenger/i) == 'micromessenger'){
    // 微信环境
    if(common.getItem('wtOpenid')){
      next()
    }else{
      var urlParams = qs.parse(window.location.search.substring(1))
      // console.log('locationsearch', window.location.search)
      if (urlParams && urlParams.code) {
        fetchOpenid({
          code: urlParams.code,
          scope:'snsapi_base'
          // scope: 'snsapi_userinfo'
        }).then(res => {
          if(res.data){
            common.setItem('wtOpenid', res.data.openId)
            // common.setItem('wtImg', res.data.headimgurl)
          }
          next()
        })
      } else {
        next()
        // callbackWechat()
      }
    }
  }else{
    // H5页面
    next()
  }

  if(to.meta.title){
    document.title = to.meta.title
  }
})

router.afterEach((to, from) => {
  window.scrollTo(0, 0)
  setTimeout(() => {
    store.commit('loading/CHANGE_LOADING', false)
  }, 300)
})

// 安卓可视区域
if(/Android/gi.test(navigator.userAgent)) {
  window.addEventListener('resize', function(){
    if(document.activeElement.tagName == 'INPUT' || document.activeElement.tagName == 'TEXTAREA') {
      window.setTimeout(function () {
        document.activeElement.scrollIntoViewIfNeeded()
        // document.activeElement.scrollIntoView(false)
      }, 100)
    }
  })
}

let vueMain =  new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
export default vueMain
