<template>
  <div class="layout">
    <keep-alive :include="keepAlive" :max="10">
      <router-view ></router-view>
    </keep-alive>
  </div>
</template>
<script>
  export default {
    computed: {
      keepAlive () {
        return this.$store.getters.keepAlive
      }
    },
  }
</script>
<style lang="less" scoped>
.layout{
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
