import {
    SET_SiteInfo_MasterScore,
    SET_SiteInfo_SiteScore,
    Page_FromArr,
    itemsArr,
    SET_Mine_bankCard,
    SET_Mine_Safeguard,
    SET_Mine_myAgreement,
    SET_Mine_myOrg,
    SET_Mine_mySite,
    SET_Audit,
    SET_MySite,
    SET_Join,
    SET_MyTask,
    SET_NewSite,
    SET_Checkpending_Audit,
    SET_Home_MySite,
    SET_Home_ToBeAudited,
    SET_Home_SiteManage,
    SET_Mine_BuildedSite,
    SET_Mine_OpenCardNum,
    SET_Mine_PointBalance,
    SET_Mine_SignedSite,
    SET_Home_MyTask,
    SET_Join_Join,
    SET_SiteList_NewSite,
    SET_SiteList_PerformanceStatistics,
    SET_SiteList_ToBeAudited,
    SET_Home_Survey,
    SET_Survey_NewSurvey,
    SET_Mine_Device,
    SET_Security_Center,
    SET_SiteList_InputManagement
} from "../mutation-types";
import {getPermissions} from "../../utils/api";
import common from "../../utils/common";

const state = {
    isShow_Home_SiteManage:true,
    isShow_Home_MyTask:true,
    isShow_Home_MySite:true,
    isShow_Home_ToBeAudited: false,
    isShow_Join_Join:false,
    isShow_Checkpending_Audit:false,
    isShow_SiteList_ToBeAudited:false,
    isShow_SiteList_PerformanceStatistics:false,
    isShow_SiteList_NewSite:false,
    isShow_Mine_mySite:false,
    isShow_Mine_myOrg:false,
    isShow_Mine_myAgreement:false,
    isShow_Mine_bankCard:false,
    isShow_Mine_Safeguard: false,
    isShow_Mine_BuildedSite:false,
    isShow_Mine_SignedSite:false,
    isShow_Mine_OpenCardNum:false,
    isShow_Mine_PointBalance:false,
    isShow_Mine_Device:false,
    isShow_SiteInfo_MasterScore:false,
    isShow_SiteInfo_SiteScore:false,
    isShow_Home_Survey:false,
    isShow_Survey_NewSurvey:false,
    isShow_Security_Center:false,
    isShow_SiteList_InputManagement:false


}

const mutations = {
    [SET_Home_SiteManage] (state, isShow)  {
        state.isShow_Home_SiteManage = isShow
    },

    [SET_Home_MyTask] (state, isShow) {
        state.isShow_Home_MyTask = isShow
    },
    [SET_Home_MySite] (state, isShow)  {
        state.isShow_Home_MySite = isShow
    },
    [SET_Home_ToBeAudited] (state, isShow)  {
      state.isShow_Home_ToBeAudited = isShow
    },


    [SET_Join_Join] (state, isShow){
        state.isShow_Join_Join = isShow
    },

    [SET_Checkpending_Audit](state, isShow) {
        state.isShow_Checkpending_Audit = isShow
    },
    [SET_SiteList_ToBeAudited] (state, isShow)  {
        state.isShow_SiteList_ToBeAudited = isShow
    },

    [SET_SiteList_PerformanceStatistics] (state, isShow)  {
        state.isShow_SiteList_PerformanceStatistics = isShow
    },

    [ SET_SiteList_NewSite] (state, isShow)  {
        state.isShow_SiteList_NewSite = isShow
    },
    [SET_Mine_mySite] (state, isShow)  {
        state.isShow_Mine_mySite = isShow
    },
    [SET_Mine_myOrg] (state, isShow)  {
        state.isShow_Mine_myOrg = isShow
    },
    [SET_Mine_myAgreement] (state, isShow)  {
        state.isShow_Mine_myAgreement = isShow
    },
    [SET_Mine_bankCard] (state, isShow)  {
        state.isShow_Mine_bankCard = isShow
    },
    [SET_Mine_Safeguard](state, isShow)  {
        state.isShow_Mine_Safeguard = isShow
    },
    [SET_Mine_BuildedSite] (state, isShow)  {
        state.isShow_Mine_BuildedSite = isShow
    },
    [SET_Mine_SignedSite] (state, isShow)  {
        state.isShow_Mine_SignedSite = isShow
    },

    [SET_Mine_OpenCardNum] (state, isShow)  {
        state.isShow_Mine_OpenCardNum = isShow
    },

    [SET_Mine_PointBalance] (state, isShow)  {
        state.isShow_Mine_PointBalance = isShow
    },
    [SET_SiteInfo_MasterScore] (state, isShow)  {
        state.isShow_SiteInfo_MasterScore = isShow
    },
    [SET_SiteInfo_SiteScore] (state, isShow)  {
        state.isShow_SiteInfo_SiteScore = isShow
    },
    [SET_Home_Survey] (state, isShow)  {
        state.isShow_Home_Survey = isShow
    },
    [SET_Survey_NewSurvey] (state, isShow)  {
        state.isShow_Survey_NewSurvey = isShow
    },
    [SET_Mine_Device] (state, isShow)  {
        state.isShow_Mine_Device = isShow
    },
    [SET_Security_Center](state,isShow){
        state.isShow_Security_Center = isShow
    },
    [SET_SiteList_InputManagement](state,isShow){
        state.isShow_SiteList_InputManagement = isShow
    }
}
const actions = {
    setPermissionAction ({commit},from) {
        return new Promise((resolve) => {
            let p = {
                token :common.getItem('wtToken')
            }
            itemsArr.forEach((item)=>{
                commit(item,false)
            })
            Page_FromArr.forEach((item)=>{
                commit(item,false)
            })
            getPermissions(p).then(res=>{
                let resData =  res.data.data
                resData.forEach((page)=>{
                    page.items.forEach((item)=>{
                        let index = itemsArr.indexOf(item)
                        if(index >-1){
                            commit(itemsArr[index],true)
                        }

                    })
                    if (from  ===  page.page ){
                        page.items.forEach((item)=>{
                            let index = Page_FromArr.indexOf(item)
                            if(index >-1){
                                commit(Page_FromArr[index],true)
                            }

                        })
                    }

                })
                resolve()
            }).catch((err)=>{
                console.log("获取权限出错了,",err)
            })

        })
    },

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
