import { getEncryptToBase64 as encrypt, getDecryptByBase64 as decrypt } from "./encryption"
import common from '@/utils/common'
var encodeReserveRE = /[!'()*]/g
var encodeReserveReplacer = c => '%' + c.charCodeAt(0).toString(16)
var commaRE = /%2C/g

var encode = str => encodeURIComponent(str)
    .replace(encodeReserveRE, encodeReserveReplacer)
    .replace(commaRE, ',')

var decode = decodeURIComponent

/**
 * 序列化对象 并加密
 * @param {Object} obj
 */
export var stringifyQuery = obj => {
    var res = obj ? Object.keys(obj).map(key => {
        var val = obj[key]

        if (val === undefined) {
            return ''
        }

        if (val === null) {
            return encode(key)
        }

        if (Array.isArray(val)) {
            var result = []
            val.forEach(val2 => {
                if (val2 === undefined) {
                    return
                }
                if (val2 === null) {
                    result.push(encode(key))
                } else {
                    result.push(encode(key) + '=' + encode(val2))
                }
            })
            return result.join('&')
        }

        return encode(key) + '=' + encode(val)
    }).filter(x => x.length > 0).join('&') : null
    
    return res ? `?${encrypt(res)}` : ''
}

/**
 * 解密  反序列化字符串参数
 * @param {String}} query
 */
export var parseQuery = query => {
    var res = {}
    query = query.trim().replace(/^(\?|#|&)/, '')

    if (!query) {
      return res
    }
    
    if (query.indexOf('jumpFrom=') == -1){
      if(query.indexOf('benefitId') == -1 ){ // 权益平台不解密，直接调过来
        // 解密
        query = decrypt(query);
      }
    }
    
    query.split('&').forEach(param => {
      console.log('2')
        var parts = param.replace(/\+/g, ' ').split('=')
        var key = decode(parts.shift())
        var val = parts.length > 0
            ? decode(parts.join('='))
            : null

        if (res[key] === undefined) {
            res[key] = val
        } else if (Array.isArray(res[key])) {
            res[key].push(val)
        } else {
            res[key] = [res[key], val]
        }
    })
    
    if(res.jumpFrom != undefined){
        console.log("app来了：",res)

        common.setItem('jumpFrom', res.jumpFrom)
        common.removeItem('wtToken')
        common.removeItem('uniqueNo')
        common.removeItem('ossID')
        common.removeItem('masterNo')
        common.removeItem('stationNo')
        common.removeItem('userRole')
        common.removeItem('isAudit')
        if (res.token != undefined){
            common.setItem('wtToken', res.token)
            console.log("congapp存入token：",common.getItem('wtToken'))
        }
        if (res.uniqueNo != undefined){
            common.setItem('uniqueNo', res.uniqueNo)
        }
        if (res.userName != undefined){
            common.setItem('userName', res.userName)
        }
        if (res.signZZ != undefined){
            common.setItem('signZZ', res.signZZ)
        }
        if (res.masterNo != undefined){
            console.log("cong app 来的 市场人员masterNo：",res.masterNo)
            common.setItem('masterNo', res.masterNo)
        }
        if (res.stationNo != undefined){
            common.setItem('stationNo', res.stationNo)
        } if (res.userRole != undefined){
            common.setItem('userRole', res.userRole)
        }
        if (res.isAudit != undefined){
            common.setItem('isAudit', res.isAudit)
        }
        if (res.id != undefined){
            common.setItem('ossID', res.id)
        }
    }

    console.log('res.jumpFrom',res)
    return res
}
