import { CHANGE_LOADING } from '../mutation-types'

export default {
  namespaced: true,
  state: {
    isLoading: false
  },
  mutations: {
    [CHANGE_LOADING] (state, payload) {
      state.isLoading = payload
    }
  },
  actions: {

  }
}